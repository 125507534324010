import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const CampaignApplicationSlide = createSlice({
    name: "campaignApplication",
    initialState: {
        page: 0,
        pageCount: 0,
        totalRow: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,
        isOpenApprovalDrawer: false,
        selectedApplication: null
    },
    reducers: {
        doGetCampaignApplicationListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
            state.pageCount = action.payload?.page_count;
            state.totalRow = action.payload?.total_row;
        },
        doGetCampaignApplicationListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
            state.pageCount = 0;
        },
        doUpdateStatusCampaignApplicationSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
            state.responseData = action.payload;
        },
        doUpdateStatusCampaignApplicationFail: (state, action) => {
            state.success = false;
            state.fail = true;
            state.errorDetailData = action.payload;
        },
        resetCampaignApplicationStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
            state.errorDetailData = null;
        },
        updateIsOpenApprovalDrawer: (state, action) => {
            state.isOpenApprovalDrawer = action.payload;
        },
        updateSelectedApplication: (state, action) => {
            state.selectedApplication = action.payload;
        },
    },
});

export const doGetCampaignApplicationList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign-application?page=${param.page}&size=${param.limit}`;
        if (param.campaign_id) {
            url += `&campaign_id=${param.campaign_id}`;
        }

        if (param.influencer_id) {
            url += `&influencer_id=${param.influencer_id}`;
        }

        const response = await axios.get(url);
        dispatch(doGetCampaignApplicationListSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignApplicationListFail(error.response));
    }
};

export const doUpdateStatusCampaignApplication = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign-application/update-status`;

        const response = await axios.put(url, param);
        dispatch(doUpdateStatusCampaignApplicationSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateStatusCampaignApplicationFail(error.response));
    }
};

export const resetCampaignApplicationState = () => async (dispatch) => {
    dispatch(resetCampaignApplicationStateSuccess(false));
};

export const updateCampaignApplicationDrawerFlag = (param) => async (dispatch) => {
    dispatch(updateIsOpenApprovalDrawer(param));
};

export const updateSelectedRegisteredApplication = (param) => async (dispatch) => {
    dispatch(updateSelectedApplication(param));
};

export const {
    doGetCampaignApplicationListSuccess,
    doGetCampaignApplicationListFail,
    doUpdateStatusCampaignApplicationSuccess,
    doUpdateStatusCampaignApplicationFail,
    resetCampaignApplicationStateSuccess,
    updateIsOpenApprovalDrawer,
    updateSelectedApplication
} = CampaignApplicationSlide.actions;

export const getCampaignApplicationList = (state) => state.campaignApplication?.listData;
export const getCampaignApplicationListPage = (state) => state.campaignApplication?.page;
export const getCampaignApplicationListPageCount = (state) => state.campaignApplication?.pageCount;
export const getCampaignApplicationListTotalRow = (state) => state.campaignApplication?.totalRow;
export const getUpdateStatusCampaignApplicationSuccess = (state) => state.campaignApplication?.success;
export const getUpdateStatusCampaignApplicationFail = (state) => state.campaignApplication?.fail;
export const getUpdateStatusCampaignApplicationResponseData = (state) => state.campaignApplication?.responseData;
export const getUpdateStatusCampaignApplicationErrorDetailData = (state) => state.campaignApplication?.errorDetailData;
export const getCampaignApprovalDrawerFlag = (state) => state.campaignApplication?.isOpenApprovalDrawer;
export const getSelectedCampaignApplication = (state) => state.campaignApplication?.selectedApplication;

export default CampaignApplicationSlide.reducer;
