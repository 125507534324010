import React from "react";

const CampaignGroupList = React.lazy(() => import('src/pages/campaign_group/CampaignGroupList'));
const ViewCampaignGroup = React.lazy(() => import('src/pages/campaign_group/ViewCampaignGroup'));
const AddCampaignGroup = React.lazy(() => import('src/pages/campaign_group/AddCampaignGroup'));
const EditCampaignGroup = React.lazy(() => import('src/pages/campaign_group/EditCampaignGroup'));

const routes = [
    { path: '/dashboard/campaign-group/add', name: 'Add Campaign Group', element: AddCampaignGroup },
    { path: '/dashboard/campaign-group/edit', name: 'Edit Campaign Group', element: EditCampaignGroup },
    { path: '/dashboard/campaign-group/:param', withParam: true, exact: true, name: "", element: ViewCampaignGroup },
    { path: '/dashboard/campaign-group', name: 'Campaign Group', element: CampaignGroupList },
];

export default routes;