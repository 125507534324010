import React from "react";
import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export const ConfirmationDialog = ({ handleClose, handleSubmit, isOpen, buttonLabel, title, children, showCancel }) => {
    const { t } = useTranslation(["core", "ui", "error_message"]);

    return (
        <Dialog disableEscapeKeyDown open={isOpen} maxWidth="sm" PaperProps={{ sx: { width: "30%", minHeight: "40%", p: 1.5 } }}>
            <DialogTitle id="confirmation-dialog-title" className="text-center" style={{ color: "#4668E5" }}>
                <Typography color="primary" variant="h5">
                    {title}
                </Typography>
            </DialogTitle>

            <Divider style={{ width: "93%" }} className="m-auto" />

            <DialogContent>{children}</DialogContent>

            <DialogActions className="justify-content-center mb-2">
                <Button disableRipple onClick={handleSubmit} autoFocus className="w-50" variant="contained" size="medium">
                    {buttonLabel}
                </Button>
                
                {showCancel && (
                    <Button disableRipple onClick={handleClose} autoFocus className="w-50" variant="outlined" size="medium">
                        {t("ui.cancel", { ns: "ui"})}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialog);
