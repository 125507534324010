import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const InfluencerSlide = createSlice({
    name: "influencer",
    initialState: {
        page: 0,
        pageCount: 0,
        totalRow: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        errorDetailData: null,
        insightCasting: null,
        insightSuccess: false,
        insightFail: false,
        castingSuccess: false,
        castingFail: false,
        campaignPage: 0,
        campaignPageCount: 0,
        campaignTotalRow: 0,
        campaignListData: null,
        updateInternalMemoResponseSuccess: false,
        updateInternalMemoResponseFail: false,
        updateInternalMemoResponseData: null,
        updateTagResponseSuccess: false,
        updateTagResponseFail: false,
        updateTagResponseData: null,
        activityLogPage: 0,
        activityLogPageCount: 0,
        activityLogTotalRow: 0,
        activityLogListData: null,
        insightInstagram: null,
        insightTiktok: null,
        resendVerifyEmailSuccess: false,
        resendVerifyEmailFail: false,
    },
    reducers: {
        doGetInfluencerListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
            state.totalRow = action.payload?.total_row;
        },
        doGetInfluencerListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
            state.totalRow = 0;
        },
        doGetInfluencerDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetInfluencerDetailFail: (state, action) => {
            state.detailData = null;
        },
        doUpdateInfluencerTagSuccess: (state, action) => {
            state.updateTagResponseSuccess = true;
            state.updateTagResponseFail = false;
            state.updateTagResponseData = action.payload.data;
        },
        doUpdateInfluencerTagFail: (state, action) => {
            state.updateTagResponseSuccess = false;
            state.updateTagResponseFail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateInfluencerInternalMemoSuccess: (state, action) => {
            state.updateInternalMemoResponseSuccess = true;
            state.updateInternalMemoResponseFail = false;
            state.updateInternalMemoResponseData = action.payload.data;
        },
        doUpdateInfluencerInternalMemoFail: (state, action) => {
            state.updateInternalMemoResponseSuccess = false;
            state.updateInternalMemoResponseFail = true;
			state.errorDetailData = action.payload;
        },
        doGetInfluencerInsightCastingSuccess: (state, action) => {
            state.insightCasting = action.payload.data;
        },
        doGetInfluencerInsightCastingFail: (state, action) => {
            state.insightCasting = null;
        },
        doGetInfluencerInsightInstagramSuccess: (state, action) => {
            state.insightInstagram = action.payload.data;
        },
        doGetInfluencerInsightInstagramFail: (state, action) => {
            state.insightInstagram = null;
        },
        doUpdateInfluencerInsightSuccess: (state, action) => {
            state.insightSuccess = true;
            state.insightFail = false;
            state.responseData = action.payload;
        },
        doUpdateInfluencerInsightFail: (state, action) => {
            state.insightSuccess = false;
            state.insightFail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateInfluencerCastingSuccess: (state, action) => {
            state.castingSuccess = true;
            state.castingFail = false;
            state.responseData = action.payload;
        },
        doUpdateInfluencerCastingFail: (state, action) => {
            state.castingSuccess = false;
            state.castingFail = true;
			state.errorDetailData = action.payload;
        },
        doGetInfluencerCampaignListSuccess: (state, action) => {
            state.campaignListData = action.payload.data;
            state.campaignPage = action.payload?.current_page;
			state.campaignPageCount = action.payload?.page_count;
            state.campaignTotalRow = action.payload?.total_row;
        },
        doGetInfluencerCampaignListFail: (state, action) => {
            state.campaignListData = null;
            state.campaignPage = 0;
			state.campaignPageCount = 0;
            state.campaignTotalRow = 0;
        },
        doGetActivityLogListSuccess: (state, action) => {
            state.activityLogListData = action.payload.data;
            state.activityLogPage = action.payload?.current_page;
			state.activityLogPageCount = action.payload?.page_count;
            state.activityLogTotalRow = action.payload?.total_row;
        },
        doGetActivityLogListFail: (state, action) => {
            state.activityLogListData = null;
            state.activityLogPage = 0;
			state.activityLogPageCount = 0;
            state.activityLogTotalRow = 0;
        },
        doResendVerifyEmailSuccess: (state, action) => {
            state.resendVerifyEmailSuccess = true;
            state.resendVerifyEmailFail = false;
			state.errorDetailData = null;
        },
        doResendVerifyEmailFail: (state, action) => {
            state.resendVerifyEmailSuccess = false;
            state.resendVerifyEmailFail = true;
			state.errorDetailData = action.payload;
        },
        resetInfluencerStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
			state.errorDetailData = null;
        },
        resetInfluencerSuccessStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.errorDetailData = null;
            state.insightCasting = null;
            state.insightSuccess = action.payload;
            state.insightFail = action.payload;
            state.castingSuccess = action.payload;
            state.castingFail = action.payload;
            state.updateInternalMemoResponseSuccess = action.payload;
            state.updateInternalMemoResponseFail = action.payload;
            state.updateInternalMemoResponseData = null;
            state.updateTagResponseSuccess = action.payload;
            state.updateTagResponseFail = action.payload;
            state.updateTagResponseData = null;
            state.resendVerifyEmailSuccess = action.payload;
            state.resendVerifyEmailFail = action.payload;
        },
    },
});

export const doGetInfluencerList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/influencer?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    if (field === 'filterTag' || field === 'filterRace' || field === 'filterInterest' 
                        || field === 'filterState' || field === 'filterCity'
                    ) {
                        let array = [];
                        if(value.length > 0) {
                            for (let i = 0; i < value.length; i++) {
                                array.push(value[i].value);
                            }
    
                            url += `&${field}=${array.toString()}`;
                        } 
                    } else if (field === 'filterProvider') {
                        let array = [];
                        if(value.length > 0) {
                            for (let i = 0; i < value.length; i++) {
                                array.push(value[i].value);
                            }

                            url += `&${field}=${array.toString()}`;
                        }
                    } else if (field === 'filterTier') {
                        let array = [];
                        if (value.length > 0) {
                            for (let i = 0; i < value.length; i++) {
                                array.push(value[i].value);
                            }
    
                            url += `&${field}=${array.toString()}`;
                        }
                    }
                    else {
                        url += `&${field}=${value}`;
                    }  
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetInfluencerListSuccess(response.data));
    } catch (error) {
        dispatch(doGetInfluencerListFail(error.response));
    }
};

export const doGetInfluencerDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/influencer/${param.influencer_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetInfluencerDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetInfluencerDetailFail(error.response));
    }
};

export const doUpdateInfluencerTag = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/influencer/${param.influencer_id}/update-tag`;
       
        const response = await axios.put(url, param);
        dispatch(doUpdateInfluencerTagSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateInfluencerTagFail(error.response));
    }
};

export const doUpdateInfluencerInternalMemo = (param, influencer_id) => async (dispatch) => {
    try {
        let url = `/api/admin/influencer/${influencer_id}/update-internal-memo`;
       
        const response = await axios.put(url, param);
        dispatch(doUpdateInfluencerInternalMemoSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateInfluencerInternalMemoFail(error.response));
    }
};

export const doGetInfluencerInsightInstagram = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/influencer/${param.influencer_id}/insight?provider=${param.provider}`;
       
        const response = await axios.get(url);
        dispatch(doGetInfluencerInsightInstagramSuccess(response.data));
    } catch (error) {
        dispatch(doGetInfluencerInsightInstagramFail(error.response));
    }
};

export const doUpdateInfluencerInsight = (param, influencer_id) => async (dispatch) => {
    try {
        let url = `/api/admin/influencer/${influencer_id}/update-insight`;
       
        const response = await axios.put(url, param);
        dispatch(doUpdateInfluencerInsightSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateInfluencerInsightFail(error.response));
    }
};

export const doUpdateInfluencerCasting = (param, influencer_id) => async (dispatch) => {
    try {
        let url = `/api/admin/influencer/${influencer_id}/update-casting`;
       
        const response = await axios.put(url, param);
        dispatch(doUpdateInfluencerCastingSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateInfluencerCastingFail(error.response));
    }
};

export const doGetInfluencerCampaignList = (param, influencerId) => async (dispatch) => {
    try {
        let url = `/api/admin/influencer/${influencerId}/campaign?page=${param.page}&size=${param.limit}`;

        const response = await axios.get(url);
        dispatch(doGetInfluencerCampaignListSuccess(response.data));
    } catch (error) {
        dispatch(doGetInfluencerCampaignListFail(error.response));
    }
};

export const doGetActivityLogList = (param, influencerId) => async (dispatch) => {
    try {
        let url = `/api/admin/influencer/${influencerId}/activity-log?page=${param.page}&size=${param.limit}`;

        const response = await axios.get(url);
        dispatch(doGetActivityLogListSuccess(response.data));
    } catch (error) {
        dispatch(doGetActivityLogListFail(error.response));
    }
};

export const doResendVerifyEmail = (param, influencer_id) => async (dispatch) => {
    try {
        let url = `/api/admin/influencer/${influencer_id}/resend-verify-email`;
       
        const response = await axios.post(url, param);
        dispatch(doResendVerifyEmailSuccess(response.data));
    } catch (error) {
        dispatch(doResendVerifyEmailFail(error.response));
    }
};

export const resetInfluencerState = () => async (dispatch) => {
    dispatch(resetInfluencerStateSuccess(false));
 };

 export const resetInfluencerSuccessState = () => async (dispatch) => {
    dispatch(resetInfluencerSuccessStateSuccess(false));
 };

export const { 
    doGetInfluencerListSuccess, 
    doGetInfluencerListFail, 
    doGetInfluencerDetailSuccess,
    doGetInfluencerDetailFail,
    doUpdateInfluencerTagSuccess,
    doUpdateInfluencerTagFail,
    doUpdateInfluencerInternalMemoSuccess,
    doUpdateInfluencerInternalMemoFail,
    doGetInfluencerInsightInstagramSuccess,
    doGetInfluencerInsightInstagramFail,
    doUpdateInfluencerInsightSuccess,
    doUpdateInfluencerInsightFail,
    doUpdateInfluencerCastingSuccess,
    doUpdateInfluencerCastingFail,
    doGetInfluencerCampaignListSuccess,
    doGetInfluencerCampaignListFail,
    doGetActivityLogListSuccess,
    doGetActivityLogListFail,
    doResendVerifyEmailSuccess,
    doResendVerifyEmailFail,
    resetInfluencerStateSuccess,
    resetInfluencerSuccessStateSuccess,
} = InfluencerSlide.actions;

export const getInfluencerList = (state) => state.influencer?.listData;
export const getInfluencerListPage = (state) => state.influencer?.page;
export const getInfluencerListPageCount = (state) => state.influencer?.pageCount;
export const getInfluencerListTotalRow = (state) => state.influencer?.totalRow;
export const getInfluencerDetail = (state) => state.influencer?.detailData;
export const getInfluencerErrorDetailData = (state) => state.influencer?.errorDetailData;

export const getUpdateInfluencerTagSuccess = (state) => state.influencer?.updateTagResponseSuccess;
export const getUpdateInfluencerTagFail = (state) => state.influencer?.updateTagResponseFail;
export const getUpdateInfluencerTagResponseData = (state) => state.influencer?.updateTagResponseData;

export const getUpdateInfluencerInternalMemoSuccess = (state) => state.influencer?.updateInternalMemoResponseSuccess;
export const getUpdateInfluencerInternalMemoFail = (state) => state.influencer?.updateInternalMemoResponseFail;
export const getUpdateInfluencerInternalMemoResponseData = (state) => state.influencer?.updateInternalMemoResponseData;

export const getInfluencerInsightCasting = (state) => state.influencer?.insightCasting;
export const getUpdateInfluencerInsightSuccess = (state) => state.influencer?.insightSuccess;
export const getUpdateInfluencerInsightFail = (state) => state.influencer?.insightFail;
export const getUpdateInfluencerCastingSuccess = (state) => state.influencer?.castingSuccess;
export const getUpdateInfluencerCastingFail = (state) => state.influencer?.castingFail;

export const getInfluencerCampaignList = (state) => state.influencer?.campaignListData;
export const getInfluencerCampaignListPage = (state) => state.influencer?.campaignPage;
export const getInfluencerCampaignListPageCount = (state) => state.influencer?.campaignPageCount;
export const getInfluencerCampaignListTotalRow = (state) => state.influencer?.campaignTotalRow;

export const getActivityLogList = (state) => state.influencer?.activityLogListData;
export const getActivityLogListPage = (state) => state.influencer?.activityLogPage;
export const getActivityLogListPageCount = (state) => state.influencer?.activityLogPageCount;
export const getActivityLogListTotalRow = (state) => state.influencer?.activityLogTotalRow;

export const getInfluencerInsightInstagram = (state) => state.influencer?.insightInstagram;
export const getInfluencerInsightTiktok = (state) => state.influencer?.insightTiktok;

export const getResendVerifyEmailSuccess = (state) => state.influencer?.resendVerifyEmailSuccess;
export const getResendVerifyEmailFail = (state) => state.influencer?.resendVerifyEmailFail;

export default InfluencerSlide.reducer;
