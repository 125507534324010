import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const SubtaskSlide = createSlice({
    name: "subtask",
    initialState: {
        success: false,
        fail: false,
        updateActiveSuccess: false,
        updateActiveFail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,
        updateActiveResponseData: null,
        updateActiveErrorDetailData: null,
        subtaskDetail: null,
        deleteSuccess: false,
        deleteFail: false,
        deleteResponseData: null,
        deleteErrorDetailData: null,
    },
    reducers: {
        doSetSubtaskDetailSuccess: (state, action) => {
            state.subtaskDetail = action.payload;
        },
        doGetTagManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetTagManagementDetailFail: (state, action) => {
            state.detailData = null;
        },
        doAddSubtaskSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
			state.responseData = action.payload;
        },
        doAddSubtaskFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateSubtaskSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
            state.responseData = action.payload;
        },
        doUpdateSubtaskFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateSubtaskActiveSuccess: (state, action) => {
            state.updateActiveSuccess = true;
            state.updateActiveFail = false;
            state.updateActiveResponseData = action.payload.data;
        },
        doUpdateSubtaskActiveFail: (state, action) => {
            state.updateActiveSuccess = false;
            state.updateActiveFail = true;
			state.updateActiveErrorDetailData = action.payload;
        },
        doDeleteSubtaskSuccess: (state, action) => {
            state.deleteSuccess = true;
            state.deleteFail = false;
            state.deleteResponseData = action.payload.data;
        },
        doDeleteSubtaskFail: (state, action) => {
            state.deleteSuccess = false;
            state.deleteFail = true;
			state.deleteErrorDetailData = action.payload;
        },
        resetSubtaskStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            // state.subtaskDetail = null;
			state.errorDetailData = null;
            state.updateActiveSuccess = action.payload;
            state.updateActiveFail = action.payload;
            state.errorDetailData = null;
            state.deleteSuccess = action.payload;
            state.deleteFail= action.payload;
        },
    },
});

export const doGetTagManagementDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/tag-management/${param.tag_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetTagManagementDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetTagManagementDetailFail(error.response));
    }
};

export const doAddSubtask = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/subtask`;

        const response = await axios.post(url, param);
        dispatch(doAddSubtaskSuccess(response.data));
    } catch (error) {
        dispatch(doAddSubtaskFail(error.response));
    }
};

export const doUpdateSubtask = (param, subtaskId) => async (dispatch) => {
    try {
        const url = `/api/admin/subtask/${subtaskId}`;

        const response = await axios.put(url, param);
        dispatch(doUpdateSubtaskSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateSubtaskFail(error.response));
    }
};

export const doUpdateSubtaskActive = (subtaskId) => async (dispatch) => {
    try {
        const url = `/api/admin/subtask/${subtaskId}/update-active`;

        const response = await axios.put(url);
        dispatch(doUpdateSubtaskActiveSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateSubtaskActiveFail(error.response));
    }
};

export const doDeleteSubtask = (subtaskId) => async (dispatch) => {
    try {
        const url = `/api/admin/subtask/${subtaskId}`;

        const response = await axios.delete(url);
        dispatch(doDeleteSubtaskSuccess(response.data));
    } catch (error) {
        dispatch(doDeleteSubtaskFail(error.response));
    }
};

export const doSetSubtaskDetail = (param) => async (dispatch) => {
    dispatch(doSetSubtaskDetailSuccess(param));
};

export const resetSubtaskState = () => async (dispatch) => {
    dispatch(resetSubtaskStateSuccess(false));
 };

export const { 
    doGetTagManagementDetailSuccess,
    doGetTagManagementDetailFail,
    doAddSubtaskSuccess,
    doAddSubtaskFail,
    doUpdateSubtaskSuccess,
    doUpdateSubtaskFail,
    doUpdateSubtaskActiveSuccess,
    doUpdateSubtaskActiveFail,
    doDeleteSubtaskSuccess,
    doDeleteSubtaskFail,
    doSetSubtaskDetailSuccess,
    resetSubtaskStateSuccess,
} = SubtaskSlide.actions;

export const getTagManagementDetail = (state) => state.subtask?.detailData;
export const getSubtaskErrorDetailData = (state) => state.subtask?.errorDetailData;
export const getAddSubtaskSuccess = (state) => state.subtask?.success;
export const getAddSubtaskFail = (state) => state.subtask?.fail;
export const getAddSubtaskResponseData = (state) => state.subtask?.responseData;

export const getUpdateSubtaskActiveSuccess = (state) => state.subtask?.updateActiveSuccess;
export const getUpdateSubtaskActiveFail = (state) => state.subtask?.updateActiveFail;
export const getUpdateSubtaskActiveResponseData = (state) => state.subtask?.updateActiveResponseData;
export const getUpdateSubtaskActiveErrorDetailData = (state) => state.subtask?.updateActiveErrorDetailData;
export const getDeleteSubtaskSuccess = (state) => state.subtask?.deleteSuccess;
export const getDeleteSubtaskFail = (state) => state.subtask?.deleteFail;
export const getDeleteSubtaskResponseData = (state) => state.subtask?.deleteResponseData;
export const getDeleteSubtaskErrorDetailData = (state) => state.subtask?.deleteErrorDetailData;
export const getSubtaskDetail = (state) => state.subtask?.subtaskDetail;

export default SubtaskSlide.reducer;
