import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const CampaignGroupSlide = createSlice({
    name: "campaignGroup",
    initialState: {
        page: 0,
        pageCount: 0,
        totalRow: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,
    },
    reducers: {
        doSetCampaignGroupDetailSuccess: (state, action) => {
            state.detailData = action.payload;
        },
        doGetCampaignGroupListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
            state.totalRow = action.payload?.total_row;
        },
        doGetCampaignGroupListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetCampaignGroupDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetCampaignGroupDetailFail: (state, action) => {
            state.detailData = null;
        },
        doAddCampaignGroupSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
			state.responseData = action.payload;
        },
        doAddCampaignGroupFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateCampaignGroupSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doUpdateCampaignGroupFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doDeleteCampaignGroupSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doDeleteCampaignGroupFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        resetCampaignGroupStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
			state.errorDetailData = null;
        },
    },
});

export const doGetCampaignGroupList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign-group?page=${param.page}&size=${param.limit}`;
       
        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetCampaignGroupListSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignGroupListFail(error.response));
    }
};

export const doGetCampaignGroupDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign-group/${param.campaign_group_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetCampaignGroupDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignGroupDetailFail(error.response));
    }
};

export const doAddCampaignGroup = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign-group`;

        const response = await axios.post(url, param);
        dispatch(doAddCampaignGroupSuccess(response.data));
    } catch (error) {
        dispatch(doAddCampaignGroupFail(error.response));
    }
};

export const doUpdateCampaignGroup = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign-group/${param.campaign_group_id}`;

        const response = await axios.put(url, param);
        dispatch(doUpdateCampaignGroupSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateCampaignGroupFail(error.response));
    }
};

export const doDeleteCampaignGroup = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign-group/${param.campaign_group_id}`;

        const response = await axios.delete(url);
        dispatch(doDeleteCampaignGroupSuccess(response.data));
    } catch (error) {
        dispatch(doDeleteCampaignGroupFail(error.response));
    }
};

export const doSetCampaignGroupDetail = (param) => async (dispatch) => {
    dispatch(doSetCampaignGroupDetailSuccess(param));
};

export const resetCampaignGroupState = () => async (dispatch) => {
    dispatch(resetCampaignGroupStateSuccess(false));
};

export const { 
    doGetCampaignGroupListSuccess, 
    doGetCampaignGroupListFail, 
    doGetCampaignGroupDetailSuccess,
    doGetCampaignGroupDetailFail,
    doAddCampaignGroupSuccess,
    doAddCampaignGroupFail,
    doUpdateCampaignGroupSuccess,
    doUpdateCampaignGroupFail,
    doDeleteCampaignGroupSuccess,
    doDeleteCampaignGroupFail,
    doSetCampaignGroupDetailSuccess,
    resetCampaignGroupStateSuccess,
} = CampaignGroupSlide.actions;

export const getCampaignGroupList = (state) => state.campaignGroup?.listData;
export const getCampaignGroupListPage = (state) => state.campaignGroup?.page;
export const getCampaignGroupListPageCount = (state) => state.campaignGroup?.pageCount;
export const getCampaignGroupListTotalRow = (state) => state.campaignGroup?.totalRow;
export const getCampaignGroupDetail = (state) => state.campaignGroup?.detailData;
export const getCampaignGroupErrorDetailData = (state) => state.campaignGroup?.errorDetailData;
export const getAddCampaignGroupSuccess = (state) => state.campaignGroup?.success;
export const getAddCampaignGroupFail = (state) => state.campaignGroup?.fail;
export const getAddCampaignGroupResponseData = (state) => state.campaignGroup?.responseData;
export const getDeleteCampaignGroupSuccess = (state) => state.campaignGroup?.success;
export const getDeleteCampaignGroupFail = (state) => state.campaignGroup?.fail;

export default CampaignGroupSlide.reducer;
