import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const payoutSlide = createSlice({
    name: "payout",
    initialState: {
        overviewPage: 0,
        overviewPageCount: 0,
        overviewTotalRow: 0,
        overviewListData: null,
        cashoutPage: 0,
        cashoutPageCount: 0,
        cashoutTotalRow: 0,
        cashoutListData: null,
        success: false,
        fail: false,
        detailData: null,
        errorDetailData: null,
        overviewSummaryData: null,
        cashoutSummaryData: null,
        cashoutRequestCampaignList: null,
        transactionPage: 0,
        transactionPageCount: 0,
        transactionTotalRow: 0,
        transactionListData: null,
        transactionSuccess: false,
        transactionFail: false,
        transactionDetailData: null,
        transactionErrorDetailData: null,
        isOpenPayDrawer: false,
        cashoutRequestData: null,
    },
    reducers: {
        doGetPayoutOverviewSummarySuccess: (state, action) => {
            state.overviewSummaryData = action.payload.data;
        },
        doGetPayoutOverviewSummaryFail: (state, action) => {
            state.overviewSummaryData = null;
        },
        doGetPayoutOverviewListSuccess: (state, action) => {
            state.overviewListData = action.payload.data;
            state.overviewPage = action.payload?.current_page;
			state.overviewPageCount = action.payload?.page_count;
            state.overviewTotalRow = action.payload?.total_row;
        },
        doGetPayoutOverviewListFail: (state, action) => {
            state.overviewListData = null;
            state.overviewPage = 0;
			state.overviewPageCount = 0;
        },
        doGetCashoutRequestSummarySuccess: (state, action) => {
            state.cashoutSummaryData = action.payload.data;
        },
        doGetCashoutRequestSummaryFail: (state, action) => {
            state.cashoutSummaryData = null;
        },
        doGetCashoutRequestListSuccess: (state, action) => {
            state.cashoutListData = action.payload.data;
            state.cashoutPage = action.payload?.current_page;
			state.cashoutPageCount = action.payload?.page_count;
            state.cashoutTotalRow = action.payload?.total_row;
        },
        doGetCashoutRequestListFail: (state, action) => {
            state.cashoutListData = null;
            state.cashoutPage = 0;
			state.cashoutPageCount = 0;
        },
        doGetCashoutRequestCampaignListSuccess: (state, action) => {
            state.cashoutRequestCampaignList = action.payload;
        },
        doGetCashoutRequestCampaignListFail: (state, action) => {
            state.cashoutRequestCampaignList = null;
        },
        doUpdateCashoutRequestSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
            state.responseData = action.payload;
        },
        doUpdateCashoutRequestFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doGetTransactionListSuccess: (state, action) => {
            state.transactionListData = action.payload.data;
            state.transactionPage = action.payload?.current_page;
			state.transactionPageCount = action.payload?.page_count;
            state.transactionTotalRow = action.payload?.total_row;
        },
        doGetTransactionListFail: (state, action) => {
            state.transactionListData = null;
            state.transactionPage = 0;
			state.transactionPageCount = 0;
        },
        doApproveTransactionSuccess: (state, action) => {
            state.transactionSuccess = true;
            state.transactionFail = false;
            state.transactionDetailData = action.payload;
        },
        doApproveTransactionFail: (state, action) => {
            state.transactionSuccess = false;
            state.transactionFail = true;
			state.transactionErrorDetailData = action.payload;
        },
        resetPayoutStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
            state.errorDetailData = null;
            state.transactionSuccess = action.payload;
            state.transactionFail = action.payload;
            state.transactionDetailData = null;
            state.transactionErrorDetailData = null;
            state.transactionSuccess = action.payload;
            state.transactionFail = action.payload;
            state.transactionDetailData = null;
            state.transactionErrorDetailData = null;
        },
        updateIsOpenPayDrawer: (state, action) => {
            state.isOpenPayDrawer = action.payload;
        },
        setCashoutRequestDataSuccess: (state, action) => {
            state.cashoutRequestData = action.payload;
        },
    },
});

export const doGetPayoutOverviewSummary = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/payout/overview-summary`;

        let existFilter = false;

        if (param.filter !== null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    if (field === 'filterYear' || field === 'filterMonth') {
                        if(existFilter === false) {
                            url += `?`;
                            existFilter = true;
                        } else {
                            url += `&`;
                        }
                    
                        url += `${field}=${value}`;
                    }  
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetPayoutOverviewSummarySuccess(response.data));
    } catch (error) {
        dispatch(doGetPayoutOverviewSummaryFail(error.response));
    }
};

export const doGetPayoutOverviewList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/payout/overview?page=${param.page}&size=${param.limit}`;
        
        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                     url += `&${field}=${value}`; 
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetPayoutOverviewListSuccess(response.data));
    } catch (error) {
        dispatch(doGetPayoutOverviewListFail(error.response));
    }
};

export const doGetCashoutRequestSummary = () => async (dispatch) => {
    try {
        let url = `/api/admin/payout/cashout-request-summary`;
        
        const response = await axios.get(url);
        dispatch(doGetCashoutRequestSummarySuccess(response.data));
    } catch (error) {
        dispatch(doGetCashoutRequestSummaryFail(error.response));
    }
};

export const doGetCashoutRequestList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/payout/cashout-request?page=${param.page}&size=${param.limit}`;
        
        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    if (field === 'filterStatus') {
                        url += `&${field}=${value.value}`;
                    } else {
                        url += `&${field}=${value}`;
                    }
                }
            }
        }
       
        const response = await axios.get(url);
        dispatch(doGetCashoutRequestListSuccess(response.data));
    } catch (error) {
        dispatch(doGetCashoutRequestListFail(error.response));
    }
};

export const doGetCashoutRequestCampaignList = (creditCashoutRequestId) => async (dispatch) => {
    try {
        let url = `/api/admin/payout/cashout-request-campaign/${creditCashoutRequestId}`;
        
        const response = await axios.get(url);
        dispatch(doGetCashoutRequestCampaignListSuccess(response.data));
    } catch (error) {
        dispatch(doGetCashoutRequestCampaignListFail(error.response));
    }
};

export const doUpdateCashoutRequest = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/payout/approve-cashout-request-status`;
       
        const response = await axios.put(url, param);
        dispatch(doUpdateCashoutRequestSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateCashoutRequestFail(error.response));
    }
};

export const doGetTransactionList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/payout/transaction-listing?page=${param.page}&size=${param.limit}`;
       
        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetTransactionListSuccess(response.data));
    } catch (error) {
        dispatch(doGetTransactionListFail(error.response));
    }
};

export const doApproveTransaction = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/payout/approve-transaction-status`;
       
        const response = await axios.put(url, param);
        dispatch(doApproveTransactionSuccess(response.data));
    } catch (error) {
        dispatch(doApproveTransactionFail(error.response));
    }
};

export const resetPayoutState = () => async (dispatch) => {
    dispatch(resetPayoutStateSuccess(false));
};

export const updateCashoutRequestPayDrawerFlag = (param) => async (dispatch) => {
    dispatch(updateIsOpenPayDrawer(param));
};

export const setCashoutRequestData = (param) => async (dispatch) => {
    dispatch(setCashoutRequestDataSuccess(param));
};

export const { 
    doGetPayoutOverviewListSuccess, 
    doGetPayoutOverviewListFail, 
    doGetPayoutOverviewSummarySuccess,
    doGetPayoutOverviewSummaryFail,
    doGetCashoutRequestListSuccess,
    doGetCashoutRequestListFail,
    doGetCashoutRequestSummarySuccess,
    doGetCashoutRequestSummaryFail,
    doGetCashoutRequestCampaignListSuccess,
    doGetCashoutRequestCampaignListFail,
    doUpdateCashoutRequestSuccess,
    doUpdateCashoutRequestFail,
    doGetTransactionListSuccess,
    doGetTransactionListFail,
    doApproveTransactionSuccess,
    doApproveTransactionFail,
    resetPayoutStateSuccess,
    updateIsOpenPayDrawer,
    setCashoutRequestDataSuccess,
} = payoutSlide.actions;

export const getPayoutOverviewList = (state) => state.payout?.overviewListData;
export const getPayoutOverviewListPage = (state) => state.payout?.overviewPage;
export const getPayoutOverviewListPageCount = (state) => state.payout?.overviewPageCount;
export const getPayoutOverviewListTotalRow = (state) => state.payout?.overviewTotalRow;
export const getCashoutRequestList = (state) => state.payout?.cashoutListData;
export const getCashoutRequestListPage = (state) => state.payout?.cashoutPage;
export const getCashoutRequestListPageCount = (state) => state.payout?.cashoutPageCount;
export const getCashoutRequestListTotalRow = (state) => state.payout?.cashoutTotalRow;
// export const getInfluencerDetail = (state) => state.payout?.detailData;
export const getInfluencerErrorDetailData = (state) => state.payout?.errorDetailData;
export const getUpdateCashoutRequestSuccess = (state) => state.payout?.success;
export const getUpdateCashoutRequestFail = (state) => state.payout?.fail;
export const getUpdateCashoutRequestResponseData = (state) => state.payout?.responseData;
export const getPayoutOverviewSummary = (state) => state.payout?.overviewSummaryData;
export const getCashoutRequestSummary = (state) => state.payout?.cashoutSummaryData;
export const getCashoutRequestCampaignList = (state) => state.payout?.cashoutRequestCampaignList;
export const getTransactionList = (state) => state.payout?.transactionListData;
export const getTransactionListPage = (state) => state.payout?.transactionPage;
export const getTransactionListPageCount = (state) => state.payout?.transactionPageCount;
export const getTransactionListTotalRow = (state) => state.payout?.transactionTotalRow;
export const getApproveTransactionSuccess = (state) => state.payout?.transactionSuccess;
export const getApproveTransactionFail = (state) => state.payout?.transactionFail;
export const getApproveTransactionResponseData = (state) => state.payout?.transactionDetailData;
export const getApproveTransactionErrorDetailData = (state) => state.payout?.transactionErrorDetailData;
export const getCashoutRequestPayDrawerFlag = (state) => state.payout?.isOpenPayDrawer;
export const getCashoutRequestData = (state) => state.payout?.cashoutRequestData;

export default payoutSlide.reducer;
