export const ENUM_PACKAGE_STATUS = {
    1: "Published",
    0: "Draft",
};

export const SUBTASKS = [
    {
        WEIGHT: 1,
        TITLE: "Receipt of goods",
    },
    {
        WEIGHT: 2,
        TITLE: "Submission of draft",
    },
    {
        WEIGHT: 3,
        TITLE: "Final report",
    },
    {
        WEIGHT: 4,
        TITLE: "Insight report",
    },
]

export const FOLLOWER_TIER = [
    {
        label: "Tier 0",
        value: 0,
        min: 500,
        max: 999
    },
    {
        label: "Tier 1",
        value: 1,
        min: 1000,
        max: 4999
    },
    {
        label: "Tier 2",
        value: 2,
        min: 5000,
        max: 19999
    },
    {
        label: "Tier 3",
        value: 3,
        min: 20000,
        max: 49999
    },
    {
        label: "Tier 4",
        value: 4,
        min: 50000,
        max: 200000
    },
    {
        label: "Tier 5",
        value: 5,
        min: 200001,
        max: 9999999999
    }
]

export const ENUM_CHANNEL_TYPE_NAME = {
    Instagram: "Instagram",
    Facebook: "Facebook",
    Youtube: "YouTube",
    Tiktok: "TikTok",
    XiaoHongShu: "XiaoHongShu",
    Twitter: "X (Twitter)",
    Lemon: "Lemon8",
};

export const CHANNEL_TYPE_ID = {
    FACEBOOK: 1,
    INSTAGRAM: 2,
    YOUTUBE: 3,
    TIKTOK: 4,
    XIAOHONGSHU: 5,
    TWITTER: 6,
    LEMON: 7,
}

export const PROFILE_SOCIAL_PAGE_PROVIDERS = [
    {
        label: "Instagram",
        value: "instagram",
    },
    {
        label: "Facebook",
        value: "facebook",
    },
    {
        label: "YouTube",
        value: "youtube",
    },
    {
        label: "TikTok",
        value: "tiktok",
    },
    {
        label: "XiaoHongShu",
        value: "xhs",
    },
    {
        label: "X (Twitter)",
        value: "twitter",
    },
    {
        label: "Lemon8",
        value: "lemon",
    },
]

export const CREDIT_TRANSACTION_STATUS = {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected`',
}

export const BACK_TYPE = {
    CAMPAIGN_LIST: 'campaign_list',
    SUBMISSION_LIST: 'submission_list',
    INFLUENCER_LIST: 'influencer_list',
    CAMPAIGN_TRACKING_List: 'campaign_tracking_list',
    BRAND_LIST: 'brand_list',
    CAMPAIGN_GROUP_LIST: 'campaign_group_list',
    TAG_LIST: 'tag_list',
    USER_LIST: 'user_list',
}

export const TARGET_RECEIVER_TYPE = {
	PUBLIC: "public",
	INVITATION_BY_REQUIREMENTS: "invitation_by_requirements",
    INVITATION_TO_INFLUENCER: "invitation_by_influencer",
    TAG: "tag"
}

export const CAMPAIGN_REPORT_FORMAT_OPTIONS = [
    {
        label: "Campaign Report",
        value: 1,
    },
    {
        label: "Campaign Review Report",
        value: 2,
    },
]

export const CAMPAIGN_REPORT_FORMAT = {
	INFLUENCER_CAMPAIGN_REPORT: 1,
    CAMPAIGN_REVIEW_REPORT: 2,
}