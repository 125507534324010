import * as React from "react";
import { Controller } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

const InputTableSelectControl = ({ 
    control, name, label, options, defaultValue, disabled, handleChange, required, hideSelectMenuItem=false, selectMenuItemTitle="", ...props 
}) => {
    const { t } = useTranslation(["core", "ui", "error_message"]);
    let optionsParam = options;
    const handleChangeFunction = handleChange ? handleChange : function(){};

    if (props?.type === "brandList" 
            || props?.type === "genderList" 
            || props?.type === "campaignGroupList" 
            || props?.type === "categoryList" 
            || props?.type === "campaignTypeList" 
            || props?.type === "roleList" 
            || props?.type === "channelList" 
            || props?.type === "contentTypeList"
            || props?.type === "campaignList"
            || props?.type === "influencerList"
            || props?.type === "genderList"
            || props?.type === "stateList"
            || props?.type === "creatorList"
            || props?.type === "campaignCategoryList"
            || props?.type === "campaignCategoryList"
            || props?.type === "emailTemplateList"
    ) {
        optionsParam = options?.map((i) => {
            return { value: i?.id, label: i?.name };
        });
    }
   
    return (
        <Box>
            <Typography variant="caption" component="div" mb={0.5} sx={{ color: "#495057", fontSize: "14px", fontWeight: "500" }}>
                {label} <span style={{color: "#d32f2f"}}>{required == true ? "*" : ""}</span>
            </Typography>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                    <FormControl fullWidth error={invalid} sx={{ height: "40px" }}>
                        <Select
                            required
                            size="small"
                            disabled={disabled}
                            value={value}
                            // onChange={onChange}
                            onChange={(e) => {
                                onChange(e);
                                handleChangeFunction(e);
                            }}
                            displayEmpty
                            IconComponent={() => <ExpandMoreIcon fontSize="small" sx={{ color: "#7e7996", marginRight: "5px" }} />}
                            MenuProps={{
                                sx: {
                                    "& .MuiMenu-paper": {
                                        backgroundColor: "dark.primary",
                                        borderRadius: ".25rem !important",
                                        color: "text.light",
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "dark.secondary",
                                        borderRadius: ".25rem !important",
                                        color: "text.white",
                                    },
                                    "& .Mui-selected": {
                                        backgroundColor: "rgba(81,86,190,.1) !important",
                                        borderRadius: ".25rem !important",
                                        color: "text.white",
                                    },
                                },
                            }}
                            sx={{
                                "&.MuiOutlinedInput-root.Mui-disabled": {
                                    backgroundColor: "rgba(206, 212, 218, 0.5)"
                                },
                                "&.MuiInputBase-root": {
                                    height: "40px",
                                    background: "#ffffff",
                                    color: "#1E1C0C",
                                    fontSize: "smaller",
                                    borderRadius: ".25rem",
                                    "&:hover": {
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#ced4da !important",
                                        },
                                    }, 
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #ced4da",
                                },
                                ".MuiOutlinedInput-notchedOutline": {
                                    height: "40px",
                                    top: "0px",
                                    borderColor: "#ced4da",
                                }
                            }}
                        >
                            {hideSelectMenuItem === false && (
                                <MenuItem value="">
                                    -- {selectMenuItemTitle != "" ? selectMenuItemTitle : t("ui.select", { ns: "ui"})} --
                                </MenuItem>
                            )}
                                
                            {optionsParam &&
                                optionsParam?.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                        {(props?.type === "genderList" 
                                            || props?.type === "influencerStatusList"  
                                            || props?.type === "influencerSubtaskStatusList"
                                            || props?.type === "statusList"
                                            || props?.type === "roleList") 
                                        ? t(`ui.${item.label}`, { ns: "ui"})
                                        : item.label
                                        }
                                    </MenuItem>
                                ))}
                        </Select>
                        <FormHelperText>{error?.message ? t(`error_message.${error?.message}`, { ns: "error_message"})  : ""}</FormHelperText>
                    </FormControl>
                )}
            />
        </Box>
    );
};
export default InputTableSelectControl;
