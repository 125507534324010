import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const forgotPasswordSlide = createSlice({
    name: "forgotPassword",
    initialState: {
        forgotPasswordSuccess: false,
        forgotPasswordFail: false,
        resetPasswordSuccess: false,
        resetPasswordFail: false,
        errorDetailData: null,
    },
    reducers: {
        doForgotPasswordSuccess: (state, action) => {
            state.forgotPasswordSuccess = true;
            state.forgotPasswordFail = false;
        },
        doForgotPasswordFail: (state, action) => {
            state.forgotPasswordSuccess = false;
            state.forgotPasswordFail = true;
            state.errorDetailData = action.payload.data;
        },
        doResetPasswordSuccess: (state, action) => {
            state.resetPasswordSuccess = true;
            state.resetPasswordFail = false;
        },
        doResetPasswordFail: (state, action) => {
            state.resetPasswordSuccess = false;
            state.resetPasswordFail = true;
            state.errorDetailData = action.payload.data;
        },
        resetForgotPasswordStateSuccess: (state, action) => {
            state.forgotPasswordSuccess = action.payload;
            state.forgotPasswordFail = action.payload;
            state.resetPasswordSuccess = action.payload;
            state.resetPasswordFail = action.payload;
            state.errorDetailData = null;
        },
    },
});

export const doForgotPassword = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/user/forgot-password`;

        const response = await axios.post(url, param);
        dispatch(doForgotPasswordSuccess(response.data));
    } catch (error) {
        dispatch(doForgotPasswordFail(error.response));
    }
};

export const doResetPassword = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/user/update-reset-password`;

        const response = await axios.post(url, param);
        dispatch(doResetPasswordSuccess(response.data));
    } catch (error) {
        dispatch(doResetPasswordFail(error.response));
    }
};

export const resetForgotPasswordState = () => async (dispatch) => {
    dispatch(resetForgotPasswordStateSuccess(false));
};

export const { 
    doForgotPasswordSuccess,
    doForgotPasswordFail,
    doResetPasswordSuccess,
    doResetPasswordFail,
    resetForgotPasswordStateSuccess,
} = forgotPasswordSlide.actions;

export const getForgotPasswordSuccess = (state) => state.forgotPassword?.forgotPasswordSuccess;
export const getForgotPasswordFail = (state) => state.forgotPassword?.forgotPasswordFail;
export const getResetPasswordSuccess = (state) => state.forgotPassword?.resetPasswordSuccess;
export const getResetPasswordFail = (state) => state.forgotPassword?.resetPasswordFail;
export const getErrorDetailData = (state) => state.forgotPassword?.errorDetailData;

export default forgotPasswordSlide.reducer;
