import React from "react";

const CampaignManagementList = React.lazy(() => import('src/pages/campaign_management/CampaignManagementList'));
const AddCampaignManagement = React.lazy(() => import('src/pages/campaign_management/AddCampaignManagement'));
const EditCampaignManagement = React.lazy(() => import('src/pages/campaign_management/EditCampaignManagement'));
const AddCampaignTask = React.lazy(() => import('src/pages/campaign_management/AddCampaignTask'));
const EditCampaignTask = React.lazy(() => import('src/pages/campaign_management/EditCampaignTask'));
const ViewCampaignManagement = React.lazy(() => import('src/pages/campaign_management/ViewCampaignManagement'));
const ViewCampaignApplication = React.lazy(() => import('src/pages/campaign_management/view_campaign_application/index'));
const ViewCampaignSubmission = React.lazy(() => import('src/pages/campaign_management/view_campaign_submission/index'));
const ViewCampaignSubmissionView = React.lazy(() => import('src/pages/campaign_management/view_campaign_submission/ViewCampaignSubmission'));
const ViewInfluencerFavorite = React.lazy(() => import('src/pages/campaign_management/view_influencer_favorite/index'));
const ViewReport = React.lazy(() => import('src/pages/campaign_management/view_report/index'));
const ViewCampaignReviewReport = React.lazy(() => import('src/pages/campaign_management/view_campaign_review_report/index'));
const CreateEmailTemplate = React.lazy(() => import('src/pages/campaign_management/create_email_template/index'));
const CampaginTracking = React.lazy(() => import('src/pages/campaign_management/view_tracking_list'));

const routes = [
    { path: '/dashboard/campaign-management/email-template', name: 'Campaign Email Template', element: CreateEmailTemplate },
    { path: '/dashboard/campaign-management/view/campaign-review-report', name: 'Campaign Review Report', element: ViewCampaignReviewReport },
    { path: '/dashboard/campaign-management/view/report', name: 'Campaign Report', element: ViewReport },
    { path: '/dashboard/campaign-management/view/influencer-favorite', name: 'Favorite', element: ViewInfluencerFavorite },
    { path: '/dashboard/campaign-management/view/submission/view', name: 'View Submission', element: ViewCampaignSubmissionView },
    { path: '/dashboard/campaign-management/view/submission', name: 'Submission', element: ViewCampaignSubmission },
    { path: '/dashboard/campaign-management/view/application', name: 'Application', element: ViewCampaignApplication },
    { path: '/dashboard/campaign-management/add', name: 'New Campaign', element: AddCampaignManagement },
    { path: '/dashboard/campaign-management/edit', name: 'Edit', element: EditCampaignManagement },
    { path: '/dashboard/campaign-management', name: 'Manage', element: CampaignManagementList },
    { path: '/dashboard/campaign-management/view', name: "View Campaign", element: ViewCampaignManagement },
    { path: '/dashboard/campaign-management/add-task', name: 'New Task', element: AddCampaignTask },
    { path: '/dashboard/campaign-management/edit-task', name: 'Edit Task', element: EditCampaignTask },
    { path: '/dashboard/campaign-management/view/tracking-progress', name: 'Tracking Progress', element: CampaginTracking },
];

export default routes;