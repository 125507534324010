import React from "react"
import NumberFormat from "react-number-format"
import { useMediaQuery } from "react-responsive"

export const NumberFormatterLeadingZeros = React.forwardRef(function NumberFormatCustom(
    props,
    ref,
) {
    const { onChange, name, ...other } = props

    return (
        <NumberFormat
            {...other}
            allowLeadingZeros
            allowNegative={false}
            decimalSeparator={false}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: name,
                        value: values.value,
                    },
                })
            }}
        />
    )
})

export const NumberFormatter = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, name, ...other } = props

    return (
        <NumberFormat
            {...other}
            allowNegative={false}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: name,
                        value: values.value,
                    },
                })
            }}
        />
    )
})

export function EnsureHttps(url) {
    if (url !== null && url !== "") {
        // Check if the URL starts with "http://"
        if (!url?.startsWith("http://") && !url?.startsWith("https://")) {
            // If it doesn't start with either, add "https://"
            url = "https://" + url;
        }
    }
    return url;
}

export function isURL(str) {
    var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    var url = new RegExp(urlRegex, 'i');
    return str?.length < 2083 && url?.test(str);
}

export const DesktopView = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1823 })
    return isDesktop ? children : null
}

export const TabletView = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}

export const BigDesktopView = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1824 })
    return isDesktop ? children : null
}

export const DefaultView = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

export const MobilePortraitView = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })

    return isMobile && isPortrait ? children : null
}

export const MobileLandscapeView = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const isLandscape = useMediaQuery({ query: "(orientation: landscape)" })

    return isMobile && isLandscape ? children : null
}

export function replaceWithBr(text) {
    return text?.replace(/\n/g, "<br />")
}

export function addCommaAfterThousandWithTwoDecimalPlace(rangeString) {
    // const formattedRange = rangeString.replace(/\d+/g, (match) => parseInt(match).toLocaleString());
    let formattedRange = "";
    if (rangeString === "0" || rangeString === 0) {
        return "0.00";
    } else {
        if (rangeString !== undefined && rangeString !== null) {
            const stringValue = rangeString.toString();

            formattedRange = stringValue?.replace(/\d+\.\d+/g, (match) => {
                const [integerPart, decimalPart] = match?.split('.');
                const formattedIntegerPart = parseInt(integerPart).toLocaleString();
                return `${formattedIntegerPart}.${decimalPart}`;
            });
        }
    }
    return formattedRange;
}

export function addCommaAfterThousand(rangeString) {
    let formattedRange = "";

    if (rangeString !== undefined && rangeString !== null) {
        const stringValue = rangeString.toString();
        formattedRange = stringValue?.replace(/\d+/g, (match) => parseInt(match).toLocaleString());
    }
    return formattedRange;
}