import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const coreSlide = createSlice({
    name: "core",
    initialState: {
        sidebarShow: true,
        commonData: null,
        loggedIn: false,
        urlParam: null,
        loginFailedDetail: null,
        loginSuccess: false,
        logoutSuccess: false,
        logoutFail: false,

        clientLoginFailedDetail: null,
        clientAuthenticateSuccess: false,
        clientLogoutSuccess: false,
        clientLogoutFail: false,
        clientLoggedIn: null,
        campaignId: null,
        listData: [],
        page: 0,
        pageCount: 0,
        totalRow: 0,
        detailData: null,
        commentSuccess: false,
        commentFail: false,
        commentErrorDetailData: null,

        loginPath: null,
    },
    reducers: {
        setSideBarShow: (state, action) => {
            state.sidebarShow = action.payload;
        },
        setUrlParam: (state, action) => {
            state.urlParam = action.payload;
        },
        setCommonData: (state, action) => {
            state.commonData = action.payload.meta;
        },
        doLoginSuccess: (state, action) => {
            state.user = action.payload.data;
            state.loginSuccess = true;
            state.logoutSuccess = false;
            state.loginFailedDetail = null;
        },
        doLoginFail: (state, action) => {
            state.loggedIn = null;
            state.loginSuccess = false;
            state.loginFailedDetail = action.payload.data;
        },
        resetLoginFail: (state, action) => {
            state.loginFailedDetail = null;
        },
        doLoggedInSuccess: (state, action) => {
            state.loggedIn = action.payload;
            state.listData = action.payload?.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
            state.totalRow = action.payload?.total_row;
        },
        doLoggedInFail: (state, action) => {
            state.loggedIn = null;
        },
        doLogoutSuccess: (state, action) => {
            state.loggedIn = null;
            state.loginSuccess = false;
            state.logoutSuccess = true;
            state.logoutFail = false;
        },
        doLogoutFail: (state, action) => {
            state.logoutSuccess = false;
            state.logoutFail = true;
        },

        doClientAuthenticateSuccess: (state, action) => {
            state.clientAuthenticateSuccess = true;
            state.clientLoginFailedDetail = null;
        },
        doClientAuthenticateFail: (state, action) => {
            state.clientLoggedIn = null;
            state.clientAuthenticateSuccess = false;
            state.clientLoginFailedDetail = action?.payload?.data
            state.clientLogoutSuccess = false;
        },
        doClientLogoutSuccess: (state, action) => {
            state.clientLogoutSuccess = true;
            state.clientLogoutFail = false;
            state.clientAuthenticateSuccess = false;
        },
        doClientLogoutFail: (state, action) => {
            state.clientLogoutSuccess = false;
            state.clientLogoutFail = true;
        },
        doClientLoggedInSuccess: (state, action) => {
            state.clientLoggedIn = action.payload;
            state.listData = action.payload?.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
            state.totalRow = action.payload?.total_row;
        },
        doClientLoggedInFail: (state, action) => {
            state.clientLoggedIn = null;
        },
        setClientCampaignId: (state, action) => {
            state.campaignId = action.payload;
        },
        resetLoggedInState: (state, action) => {
            state.loggedIn = null;
        },
        doGetDraftReviewDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetDraftReviewDetailFail: (state, action) => {
            state.detailData = null;
        },
        doSubmitDraftReviewCommentSuccess: (state, action) => {
            state.commentSuccess = true;
            state.commentFail = false;
        },
        doSubmitDraftReviewCommentFail: (state, action) => {
            state.commentSuccess = false;
            state.commentFail = true;
            state.commentErrorDetailData = action.payload;
        },
        resetDraftReviewComment: (state, action) => {
            state.commentSuccess = action.payload;
            state.commentFail = action.payload;
            state.commentErrorDetailData = null;
        },
        setLoginPath: (state, action) => {
            state.loginPath = action.payload;
        },
        resetClientLoginState: (state, action) => {
            state.clientLoggedIn = null;
            state.clientAuthenticateSuccess = action.payload;
            state.clientLogoutSuccess = action.payload;
            state.clientLogoutFail = action.payload;
        },
    },
});

export const doLogin = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/user/login`;
        const response = await axios.post(url, param);
        dispatch(doLoginSuccess(response.data));
    } catch (error) {
        dispatch(doLoginFail(error.response));
    }
};

export const doGetCommonData = () => async (dispatch) => {
    try {
        const response = await axios.get("/api/admin/commons/meta");
        dispatch(setCommonData({ meta: response.data }));
    } catch (error) {
        dispatch(setCommonData({ meta: null }));
    }
};

export const doGetLoggedInUserDetails = () => async (dispatch) => {
	try {
        const url = `/api/admin/user/me`;
        const response = await axios.get(url);
        dispatch(doLoggedInSuccess(response.data));
    } catch (error) {
        dispatch(doLoggedInFail(error.response));
    }
};

export const doLogout = () => async (dispatch) => {
    try {
        const url = `/api/admin/user/logout`;
        const response = await axios.get(url);
        dispatch(doLogoutSuccess(response.data));
    } catch (error) {
        dispatch(doLogoutFail(error.response));
    }
};

export const doClientAuthenticate = (param, campaignId) => async (dispatch) => {
    try {
        const url = `/api/client/draft-review/${campaignId}/authenticate`;
        const response = await axios.post(url, param);
        dispatch(doClientAuthenticateSuccess(response.data));
    } catch (error) {
        dispatch(doClientAuthenticateFail(error.response));
    }
};

export const doGetClientLoggedInDetails = (param) => async (dispatch) => {
    try {
        const url = `/api/client/draft-review/listing?page=${param.page}&size=${param.limit}`;
        const response = await axios.get(url);
        dispatch(doClientLoggedInSuccess(response.data));
    } catch (error) {
        dispatch(doClientLoggedInFail(error.response));
    }
};

export const doClientLogout = () => async (dispatch) => {
    try {
        const url = `/api/client/logout`;
        const response = await axios.get(url);
        dispatch(doClientLogoutSuccess(response.data));
    } catch (error) {
        dispatch(doClientLogoutFail(error.response));
    }
};

export const doGetDraftReviewDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/client/draft-review/view?campaignApplicationId=${param.campaignApplicationId}`;
       
        const response = await axios.get(url);
        dispatch(doGetDraftReviewDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetDraftReviewDetailFail(error.response));
    }
};

export const doSubmitDraftReviewComment = (param) => async (dispatch) => {
    try {
        const url = `/api/client/draft-review/comment`;

        const response = await axios.post(url, param);
        dispatch(doSubmitDraftReviewCommentSuccess(response.data));
    } catch (error) {
        dispatch(doSubmitDraftReviewCommentFail(error.response));
    }
};

export const resetDraftReviewCommentState = () => async (dispatch) => {
    dispatch(resetDraftReviewComment(false));
};

export const doSetUrlParam = (param) => async (dispatch) => {
   dispatch(setUrlParam(param));
};

export const updateSidebarShow = (param) => async (dispatch) => {
    dispatch(setSideBarShow(param));
};

export const doSetClientCampaignId = (param) => async (dispatch) => {
    dispatch(setClientCampaignId(param));
};

export const doSetLoginPath = (param) => async (dispatch) => {
    dispatch(setLoginPath(param));
};

export const doResetClientLoginState = (param) => async (dispatch) => {
    dispatch(resetClientLoginState(false));
};

export const { 
    setSideBarShow,
    setCommonData, 
    doLoginSuccess, 
    setUrlParam, 
    doLoginFail, 
    doLoggedInSuccess, 
    doLoggedInFail, 
    resetLoginFail,
    doLogoutSuccess,
    doLogoutFail,
    doClientAuthenticateSuccess,
    doClientAuthenticateFail,
    doClientLogoutSuccess,
    doClientLogoutFail,
    doClientLoggedInSuccess,
    doClientLoggedInFail,
    setClientCampaignId,
    resetLoggedInState,
    doGetDraftReviewDetailSuccess,
    doGetDraftReviewDetailFail,
    doSubmitDraftReviewCommentSuccess,
    doSubmitDraftReviewCommentFail,
    resetDraftReviewComment,
    setLoginPath,
    resetClientLoginState,
} = coreSlide.actions;

export const getSideBarShow = (state) => state.core?.sidebarShow;
export const getCommonData = (state) => state.core?.commonData;
export const getLoginFailedDetail = (state) => state.core?.loginFailedDetail;
export const getLoginSuccess = (state) => state.core?.loginSuccess;
export const getLoggedIn = (state) => state.core?.loggedIn;
export const getLogoutSuccess = (state) => state.core?.logoutSuccess;
export const getUrlParam = (state) => state.core?.urlParam;

export const getClientAuthenticateSuccess = (state) => state.core?.clientAuthenticateSuccess;
export const getClientLoginFailedDetail = (state) => state.core?.clientLoginFailedDetail;
export const getClientLoggedIn = (state) => state.core?.clientLoggedIn;
export const getClientLogoutSuccess = (state) => state.core?.clientLogoutSuccess;
export const getClientCampaignId = (state) => state.core?.campaignId;

export const getInfluencerList = (state) => state.core?.listData;
export const getInfluencerListPage = (state) => state.core?.page;
export const getInfluencerListPageCount = (state) => state.core?.pageCount;
export const getInfluencerListTotalRow = (state) => state.core?.totalRow;

export const getDraftReviewDetail = (state) => state.core?.detailData;
export const getSubmitDraftReviewCommentSuccess = (state) => state.core?.commentSuccess;
export const getSubmitDraftReviewCommentFail = (state) => state.core?.commentFail;
export const getSubmitDraftReviewCommentErrorDetailData = (state) => state.core.commentErrorDetailData;

export const getLoginPath = (state) => state.core.loginPath;

export default coreSlide.reducer;
