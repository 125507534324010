import React from "react";

const AddUser = React.lazy(() => import('src/pages/user_management/AddUserManagement'));
const EditUser = React.lazy(() => import('src/pages/user_management/EditUserManagement'));
const UserList = React.lazy(() => import('src/pages/user_management/UserManagementList'));

const routes = [
    { path: '/dashboard/user/edit', name: 'editUser', element: EditUser },
    { path: '/dashboard/user/add', name: 'addUser', element: AddUser },
    { path: '/dashboard/user', name: 'userManagement', element: UserList },
];

export default routes;