import React from "react";

const CampaignSubmissionList = React.lazy(() => import("src/pages/campaign_submission"));
const ViewCampaignSubmission = React.lazy(() => import("src/pages/campaign_submission/ViewCampaignSubmission"));
const CampaignApplicationList = React.lazy(() => import("src/pages/campaign_application"));
const CampaignReport = React.lazy(() => import("src/pages/campaign_report"));

const routes = [
    { path: "/dashboard/campaign/report", name: "Campaign Report", element: CampaignReport },
    { path: "/dashboard/campaign/application", name: "Registration Approval", element: CampaignApplicationList },
    { path: "/dashboard/campaign/submission/view", name: "View Submission", element: ViewCampaignSubmission },
    { path: "/dashboard/campaign/submission", name: "Submission", element: CampaignSubmissionList },
    { path: "/dashboard/campaign", name: "Campaign" },
];

export default routes;
