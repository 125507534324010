import React from "react";

const InfluencerList = React.lazy(() => import('src/pages/influencer/InfluencerList'));
const ViewInfluencer = React.lazy(() => import('src/pages/influencer/ViewInfluencer'));
const EditTagInfluencer = React.lazy(() => import('src/pages/influencer/EditTagInfluencer'));

const routes = [
    { path: '/dashboard/influencer', name: 'Influencers', element: InfluencerList },
    { path: '/dashboard/influencer/:param', withParam: true, exact: true, name: "", element: ViewInfluencer },
    { path: '/dashboard/influencer/edit-tag', name: 'Edit Influencer Tag', element: EditTagInfluencer },
];

export default routes;