import React from "react";

const BrandList = React.lazy(() => import('src/pages/brand/BrandList'));
const ViewBrand = React.lazy(() => import('src/pages/brand/ViewBrand'));
const AddBrand = React.lazy(() => import('src/pages/brand/AddBrand'));
const EditBrand = React.lazy(() => import('src/pages/brand/EditBrand'));

const routes = [
    { path: '/dashboard/brand-list/add', name: 'Add Brand', element: AddBrand },
    { path: '/dashboard/brand-list/edit', name: 'Edit Brand', element: EditBrand },
    { path: '/dashboard/brand-list/:param', withParam: true, exact: true, name: "", element: ViewBrand },
    { path: '/dashboard/brand-list', name: 'brand', element: BrandList },
];

export default routes;