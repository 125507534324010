import React from "react";
import TransactionList from "src/pages/payout/TransactionList";

const OverviewList = React.lazy(() => import('src/pages/payout/OverviewList'));
const CashoutRequestList = React.lazy(() => import('src/pages/payout/CashoutRequestList'));
const AddBrand = React.lazy(() => import('src/pages/brand/AddBrand'));
const EditBrand = React.lazy(() => import('src/pages/brand/EditBrand'));

const routes = [
    { path: '/dashboard/brand-list/add', name: 'Add', element: AddBrand },
    { path: '/dashboard/brand-list/edit', name: 'Edit', element: EditBrand },
    { path: '/dashboard/payout', name: 'Payout', },
    { path: '/dashboard/payout/cashout-request-list', name: 'Cashout Request', element: CashoutRequestList },
    { path: '/dashboard/payout/overview-list', name: 'Overview', element: OverviewList },
    { path: '/dashboard/payout/transaction-list', name: 'transaction', element: TransactionList },
];

export default routes;