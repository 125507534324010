import React from "react";

const PrivilegeList = React.lazy(() => import('src/pages/privilege/PrivilegeList'));
const EditPrivilege = React.lazy(() => import('src/pages/privilege/EditPrivilege'));

const routes = [
    { path: '/dashboard/privilege/change-role', name: 'Change Role', element: EditPrivilege },
    { path: '/dashboard/privilege', name: 'Privilege Management', element: PrivilegeList },
];

export default routes;