import axios from "axios";

import { doSetLoginPath } from "src/reducers/reducer_core";

// eslint-disable-next-line import/no-anonymous-default-export
export default (store) => {
    axios.interceptors.request.use(async (request) => {
        request.withCredentials = true
        return request
    })

    axios.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            if (error.response) {
                if (error?.response?.status === 401) {
                    if (window.location.href?.includes("draft-review")) {
                        store?.dispatch(doSetLoginPath(window.location.hash));
                        window.location = '/#/client-logout';
                    } else {
                        store?.dispatch(doSetLoginPath(window.location.hash));
                        window.location = '/#/logout';
                    }
                }
            }

            return Promise.reject(error)
        },
    )
}
