import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const CampaignManagementSlide = createSlice({
    name: "campaignManagement",
    initialState: {
        page: 0,
        pageCount: 0,
        totalRow: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,
        isOpenPostDrawer: false,
        selectedCampaignPost: null,
        detail: null,
        filterData: null,

        updateInternalMemoSuccess: false,
        updateInternalMemoFail: false,

        favoritePage: 0,
        favoritePageCount: 0,
        favoriteTotalRow: 0,
        favoriteListData: null,

        insightReportUuid: null,
        insightReportPage: 0,
        insightReportPageCount: 0,
        insightReportTotalRow: 0,
        insightReportListData: [],
        insightReportSummaryData: null,
        insightReportTop5Posts: [],

        engagementRateByTier: null,
        engagementRateByContentType: [],

        accumulatedExposure: [],
        insightReportChannelId: null,
        deleteTaskSuccess: false,

        exportReport: null,
        exportReportErrorDetailData: null,

        updateGuaranteeSuccess: false,
        updateGuaranteeFail: false,

        exportLiveReport: null,
        exportLiveReportErrorDetailData: null,

        updateEmailTemplateSuccess: false,
        updateEmailTemplateFail: false,
        updateEmailTemplateResponseData: null,

        sendBlastEmailSuccess: false,
        sendBlastEmailFail: false,

        sendTestEmailSuccess: false,
        sendTestEmailFail: false,

        duplicateCampaignSuccess: false,
        duplicateCampaignFail: false,

        getEmailTemplateJson: null,

        trackingListData: null,
        trackingPage: null,
        trackingPageCount: null,
        trackingTotalRow: null,

        campaignReviewReportPage: 0,
        campaignReviewReportPageCount: 0,
        campaignReviewReportTotalRow: 0,
        campaignReviewReportListData: [],
        campaignReviewReportSummaryData: null,
    },
    reducers: {
        doSetCampaignDetailSuccess: (state, action) => {
            state.detail = action.payload;
        },
        doGetCampaignManagementListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
            state.totalRow = action.payload?.total_row;
        },
        doGetCampaignManagementListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetCampaignManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetCampaignManagementDetailFail: (state, action) => {
            state.detailData = null;
        },
        doAddCampaignManagementSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
			state.responseData = action.payload.data;
        },
        doAddCampaignManagementFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateCampaignManagementSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
            state.responseData = action.payload.data;
        },
        doUpdateCampaignManagementFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doDeleteCampaignManagementSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doDeleteCampaignManagementFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateCampaignInternalMemoSuccess: (state, action) => {
            state.updateInternalMemoSuccess = true;
            state.updateInternalMemoFail = false;
        },
        doUpdateCampaignInternalMemoFail: (state, action) => {
            state.updateInternalMemoSuccess = false;
            state.updateInternalMemoFail = true;
			state.errorDetailData = action.payload;
        },
        doGetInfluencerFavoriteListSuccess: (state, action) => {
            state.favoriteListData = action.payload.data;
            state.favoritePage = action.payload?.current_page;
			state.favoritePageCount = action.payload?.page_count;
            state.favoriteTotalRow = action.payload?.total_row;
        },
        doGetInfluencerFavoriteListFail: (state, action) => {
            state.favoriteListData = null;
            state.favoritePage = 0;
			state.favoritePageCount = 0;
            state.favoriteTotalRow = 0;
        },
        doGetInsightReportListSuccess: (state, action) => {
            state.insightReportUuid = action.payload.uuid;
            state.insightReportListData = action.payload.data;
            state.insightReportPage = action.payload?.current_page;
			state.insightReportPageCount = action.payload?.page_count;
            state.insightReportTotalRow = action.payload?.total_row;
            state.insightReportSummaryData = action.payload.summary;
            state.insightReportTop5Posts = action.payload.top5Posts;
            state.engagementRateByTier = action.payload.engagementRateByTier;
            state.engagementRateByContentType = action.payload.engagementRateByContentType;
            state.accumulatedExposure = action.payload.totalAccumulatedExposure;
            state.insightReportChannelId = action.payload.channelId;
        },
        doGetInsightReportListFail: (state, action) => {
            state.insightReportUuid = null;
            state.insightReportListData = null;
            state.insightReportPage = 0;
			state.insightReportPageCount = 0;
            state.insightReportTotalRow = 0;
        },
        doGetExportCampaignReportSuccess: (state, action) => {
            state.exportReport = action.payload;
        },
        doGetExportCampaignReportFail: (state, action) => {
            state.exportReport = null;
            state.exportReportErrorDetailData = action.payload;
        },
        doGetExportCampaignLiveReportSuccess: (state, action) => {
            state.exportLiveReport = action.payload;
        },
        doGetExportCampaignLiveReportFail: (state, action) => {
            state.exportLiveReport = null;
            state.exportLiveReportErrorDetailData = action.payload;
        },
        doUpdateGuaranteeExposureSuccess: (state, action) => {
            state.updateGuaranteeSuccess = true;
            state.updateGuaranteeFail = false;
        },
        doUpdateGuaranteeExposureFail: (state, action) => {
            state.updateGuaranteeSuccess = false;
            state.updateGuaranteeFail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateEmailTemplateSuccess: (state, action) => {
            state.updateEmailTemplateSuccess = true;
            state.updateEmailTemplateFail = false;
            state.updateEmailTemplateResponseData = action.payload.data;
        },
        doUpdateEmailTemplateFail: (state, action) => {
            state.updateEmailTemplateSuccess = false;
            state.updateEmailTemplateFail = true;
			state.errorDetailData = action.payload;
            state.updateEmailTemplateResponseData = null;
        },
        doSendBlastEmailSuccess: (state, action) => {
            state.sendBlastEmailSuccess = true;
            state.sendBlastEmailFail = false;
        },
        doSendBlastEmailFail: (state, action) => {
            state.sendBlastEmailSuccess = false;
            state.sendBlastEmailFail = true;
        },
        doSendTestEmailSuccess: (state, action) => {
            state.sendTestEmailSuccess = true;
            state.sendTestEmailFail = false;
        },
        doSendTestEmailFail: (state, action) => {
            state.sendTestEmailSuccess = false;
            state.sendTestEmailFail = true;
        },
        doDuplicateCampaignSuccess: (state, action) => {
            state.duplicateCampaignSuccess = true;
            state.duplicateCampaignFail = false;
        },
        doDuplicateCampaignFail: (state, action) => {
            state.duplicateCampaignSuccess = false;
            state.duplicateCampaignFail = true;
        },
        doGetCampaignEmailTemplateSuccess: (state, action) => {
            state.getEmailTemplateJson = action.payload;
        },
        doGetCampaignEmailTemplateFail: (state, action) => {
            state.getEmailTemplateJson = null;
        },
        doGetCampaignTrackingProgressListSuccess: (state, action) => {
            state.trackingListData = action.payload.data;
            state.trackingPage = action.payload?.current_page;
			state.trackingPageCount = action.payload?.page_count;
            state.trackingTotalRow = action.payload?.total_row;
        },
        doGetCampaignTrackingProgressListFail: (state, action) => {
            state.trackingListData = null;
            state.trackingPage = 0;
			state.trackingTotalRow = 0;
        },
        doGetCampaignReviewReportListSuccess: (state, action) => {
            state.campaignReviewReportListData = action.payload.data;
            state.campaignReviewReportPage = action.payload?.current_page;
			state.campaignReviewReportPageCount = action.payload?.page_count;
            state.campaignReviewReportTotalRow = action.payload?.total_row;
            state.campaignReviewReportSummaryData = action.payload.summary;
        },
        doGetCampaignReviewReportListFail: (state, action) => {
            state.campaignReviewReportListData = null;
            state.campaignReviewReportPage = 0;
			state.campaignReviewReportPageCount = 0;
            state.campaignReviewReportTotalRow = 0;
        },
        setCampaignFilterData: (state, action) => {
            state.filterData = action.payload;
        },
        resetCampaignManagementStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.updateInternalMemoSuccess = action.payload;
            state.updateInternalMemoFail = action.payload;
			state.errorDetailData = null;
            state.exportReport = null;
            state.exportReportErrorDetailData= null;
            state.updateGuaranteeSuccess = action.payload;
            state.updateGuaranteeFail = action.payload;
            state.exportLiveReport = null;
            state.exportLiveReportErrorDetailData= null;
            state.updateEmailTemplateSuccess = action.payload;
            state.updateEmailTemplateFail = action.payload;
            state.sendBlastEmailSuccess = action.payload;
            state.sendBlastEmailFail = action.payload;
            state.sendTestEmailSuccess = action.payload;
            state.sendTestEmailFail = action.payload;
            state.duplicateCampaignSuccess = action.payload;
            state.duplicateCampaignFail = action.payload;
        },
        resetCampaignManagementDataStateSuccess: (state, action) => {
            state.detailData = null;
        },
        updateIsOpenPostDrawer: (state, action) => {
            state.isOpenPostDrawer = action.payload;
        },
        updateSelectedPost: (state, action) => {
            state.selectedCampaignPost = action.payload;
        },
        resetEmailTemplateStateSuccess: (state, action) => {
            state.getEmailTemplateJson = action.payload;
        },
    },
});

export const doGetCampaignManagementList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign?page=${param?.page}&size=${param?.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    if (field === 'filterCampaignGroup') {
                        url += `&${field}=${value?.value}`;
                    } else {
                        url += `&${field}=${value}`;
                    }  
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetCampaignManagementListSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignManagementListFail(error.response));
    }
};

export const doGetCampaignManagementDetail = (campaignId) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${campaignId}`;
       
        const response = await axios.get(url);
        dispatch(doGetCampaignManagementDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignManagementDetailFail(error.response));
    }
};

export const doAddCampaignManagement = (param) => async (dispatch) => {
    try {
        let formData = new FormData();
        for (const field in param) {
            const value = param[field];

            if (field !== 'campaignFile') {
                if (value !== null && value !== '') {
                    if (field === 'influencer_type' || field === 'gender' 
                        || field === 'age_range' || field === 'followers' 
                        || field === 'race' || field === 'social_media' 
                        || field === 'tags' || field === 'channel_id'
                        || field === 'content_type_id' || field === "invitations"
                    ) {
                        formData.append(
                            field, JSON.stringify(value)
                        );
                    } else {
                        formData.append(
                            field, value
                        );
                    }
                }
            }
        }

        if (param.campaignFile !== null && param.campaignFile !== "") {
            formData.append('campaignFile', param.campaignFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const url = `/api/admin/campaign`;

        const response = await axios.post(url, formData, config);
        dispatch(doAddCampaignManagementSuccess(response.data));
    } catch (error) {
        dispatch(doAddCampaignManagementFail(error.response));
    }
};

export const doUpdateCampaignManagement = (param, campaignId) => async (dispatch) => {
    try {
        let formData = new FormData();
        for (const field in param) {
            const value = param[field];

            if (field !== 'campaignFile') {
                if (value !== null && value !== '') {
                    if (field === 'influencer_type' || field === 'gender' 
                        || field === 'age_range' || field === 'followers' 
                        || field === 'race' || field === 'social_media' 
                        || field === 'tags' || field === 'channel_id'
                        || field === 'content_type_id' || field === "invitations"
                    ) {
                        formData.append(
                            field, JSON.stringify(value)
                        );
                    } else {
                        formData.append(
                            field, value
                        );
                    }
                }
            }
        }

        if (param.campaignFile !== null && param.campaignFile !== "") {
            formData.append('campaignFile', param.campaignFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const url = `/api/admin/campaign/${campaignId}`;

        const response = await axios.put(url, formData, config);
        dispatch(doUpdateCampaignManagementSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateCampaignManagementFail(error.response));
    }
};

export const doDeleteCampaignManagement = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign/${param.campaign_id}`;

        const response = await axios.delete(url);
        dispatch(doDeleteCampaignManagementSuccess(response.data));
    } catch (error) {
        dispatch(doDeleteCampaignManagementFail(error.response));
    }
};

export const doGetCampaignFilterData = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/filter/data/${param?.campaign_id}`;
       
        const response = await axios.get(url);
        dispatch(setCampaignFilterData(response.data));
    } catch (error) {
        dispatch(setCampaignFilterData(error.response));
    }
};

export const doUpdateCampaignInternalMemo = (param, campaign_id) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${campaign_id}/update-internal-memo`;
       
        const response = await axios.put(url, param);
        dispatch(doUpdateCampaignInternalMemoSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateCampaignInternalMemoFail(error.response));
    }
};

export const doGetInfluencerFavoriteList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${param?.campaignId}/influencer/favorite?page=${param?.page}&size=${param?.limit}`;
       
        const response = await axios.get(url);
        dispatch(doGetInfluencerFavoriteListSuccess(response.data));
    } catch (error) {
        dispatch(doGetInfluencerFavoriteListFail(error.response));
    }
};

export const doGetInsightReportList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${param?.campaignId}/insight-report?page=${param?.page}&size=${param?.limit}`;
       
        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetInsightReportListSuccess(response.data));
    } catch (error) {
        dispatch(doGetInsightReportListFail(error.response));
    }
};

export const doGetExportCampaignReport = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign-report/export?uuid=${param?.uuid}`;
       
        const response = await axios.get(url);
        dispatch(doGetExportCampaignReportSuccess(response.data));
    } catch (error) {
        dispatch(doGetExportCampaignReportFail(error.response));
    }
};


export const doGetExportCampaignLiveReport = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign-report/export-live?uuid=${param?.uuid}`;
       
        const response = await axios.get(url);
        dispatch(doGetExportCampaignLiveReportSuccess(response.data));
    } catch (error) {
        dispatch(doGetExportCampaignLiveReportFail(error.response));
    }
};

export const doUpdateGuaranteeExposure = (param, campaignId) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign-report/${campaignId}/guarantee-exposure`;
       
        const response = await axios.put(url, param);
        dispatch(doUpdateGuaranteeExposureSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateGuaranteeExposureFail(error.response));
    }
};

export const doUpdateEmailTemplate = (param, campaign_id) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${campaign_id}/email-template`;
       
        const response = await axios.put(url, param);
        dispatch(doUpdateEmailTemplateSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateEmailTemplateFail(error.response));
    }
};

export const doSendBlastEmail = (param, campaign_id) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${campaign_id}/send-blast-email`;
       
        const response = await axios.post(url, param);
        dispatch(doSendBlastEmailSuccess(response.data));
    } catch (error) {
        dispatch(doSendBlastEmailFail(error.response));
    }
};

export const doSendTestEmail = (param, campaign_id) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${campaign_id}/send-blast-email-test`;
       
        const response = await axios.post(url, param);
        dispatch(doSendTestEmailSuccess(response.data));
    } catch (error) {
        dispatch(doSendTestEmailFail(error.response));
    }
};

export const doDuplicateCampaign = (campaign_id) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${campaign_id}/duplicate`;
       
        const response = await axios.get(url);
        dispatch(doDuplicateCampaignSuccess(response.data));
    } catch (error) {
        dispatch(doDuplicateCampaignFail(error.response));
    }
};

export const doGetCampaignEmailTemplate = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${param?.emailTemplateId}/email-template`;
       
        const response = await axios.get(url);
        dispatch(doGetCampaignEmailTemplateSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignEmailTemplateFail(error.response));
    }
};

export const doGetCampaignTrackingProgressList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${param.campaign_id}/tracking-progress?page=${param.page}&size=${param.limit}`;
        if (param.influencer) {
            url += `&influencer=${param.influencer?.value}`;
        }
        if (param.status) {
            url += `&status=${param.status}`;
        }
        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }
        const response = await axios.get(url);
        dispatch(doGetCampaignTrackingProgressListSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignTrackingProgressListFail(error.response));
    }
};

export const doGetCampaignReviewReportList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${param?.campaignId}/campaign-review-report?page=${param?.page}&size=${param?.limit}`;
       
        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetCampaignReviewReportListSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignReviewReportListFail(error.response));
    }
};

export const resetCampaignManagementState = () => async (dispatch) => {
    dispatch(resetCampaignManagementStateSuccess(false));
};

export const resetCampaignManagementDataState = () => async (dispatch) => {
    dispatch(resetCampaignManagementDataStateSuccess(false));
};

export const updateCampaignPostDrawerFlag = (param) => async (dispatch) => {
    dispatch(updateIsOpenPostDrawer(param));
};

export const updateSelectedCampaignPost = (param) => async (dispatch) => {
    dispatch(updateSelectedPost(param));
};

export const doSetCampaignDetail = (param) => async (dispatch) => {
    dispatch(doSetCampaignDetailSuccess(param));
};

export const resetEmailTemplateState = () => async (dispatch) => {
    dispatch(resetEmailTemplateStateSuccess(null));
};


export const { 
    doGetCampaignManagementListSuccess, 
    doGetCampaignManagementListFail, 
    doGetCampaignManagementDetailSuccess,
    doGetCampaignManagementDetailFail,
    doAddCampaignManagementSuccess,
    doAddCampaignManagementFail,
    doUpdateCampaignManagementSuccess,
    doUpdateCampaignManagementFail,
    doDeleteCampaignManagementSuccess,
    doDeleteCampaignManagementFail,
    setCampaignFilterData,
    doUpdateCampaignInternalMemoSuccess,
    doUpdateCampaignInternalMemoFail,
    resetCampaignManagementStateSuccess,
    resetCampaignManagementDataStateSuccess,
    updateIsOpenPostDrawer,
    updateSelectedPost,
    doGetInfluencerFavoriteListSuccess,
    doGetInfluencerFavoriteListFail,
    doGetInsightReportListSuccess,
    doGetInsightReportListFail,
    doGetExportCampaignReportSuccess,
    doGetExportCampaignReportFail,
    doUpdateGuaranteeExposureSuccess,
    doUpdateGuaranteeExposureFail,
    doSetCampaignDetailSuccess,
    doGetExportCampaignLiveReportSuccess,
    doGetExportCampaignLiveReportFail,
    doUpdateEmailTemplateSuccess,
    doUpdateEmailTemplateFail,
    doSendBlastEmailSuccess,
    doSendBlastEmailFail,
    doSendTestEmailSuccess,
    doSendTestEmailFail,
    doDuplicateCampaignSuccess,
    doDuplicateCampaignFail,
    doGetCampaignEmailTemplateSuccess,
    doGetCampaignEmailTemplateFail,
    doGetCampaignTrackingProgressListSuccess,
    doGetCampaignTrackingProgressListFail,
    doGetCampaignReviewReportListSuccess,
    doGetCampaignReviewReportListFail,
    resetEmailTemplateStateSuccess,
} = CampaignManagementSlide.actions;

export const getCampaignManagementList = (state) => state.campaignManagement?.listData;
export const getCampaignManagementListPage = (state) => state.campaignManagement?.page;
export const getCampaignManagementListPageCount = (state) => state.campaignManagement?.pageCount;
export const getCampaignManagementListTotalRow = (state) => state.campaignManagement?.totalRow;
export const getCampaignManagementDetail = (state) => state.campaignManagement?.detailData;
export const getCampaignManagementErrorDetailData = (state) => state.campaignManagement?.errorDetailData;
export const getAddCampaignManagementSuccess = (state) => state.campaignManagement?.success;
export const getAddCampaignManagementFail = (state) => state.campaignManagement?.fail;
export const getAddCampaignManagementResponseData = (state) => state.campaignManagement?.responseData;
export const getDeleteCampaignManagementSuccess = (state) => state.campaignManagement?.success;
export const getDeleteCampaignManagementFail = (state) => state.campaignManagement?.fail;
export const getCampaignPostDrawerFlag = (state) => state.campaignManagement?.isOpenPostDrawer;
export const getSelectedCampaignPost = (state) => state.campaignManagement?.selectedCampaignPost;
export const getCampaignDetail = (state) => state.campaignManagement?.detail;
export const getCampaignFilterData = (state) => state.campaignManagement?.filterData;

export const getUpdateInternalMemoSuccess = (state) => state.campaignManagement?.updateInternalMemoSuccess;
export const getUpdateInternalMemoFail = (state) => state.campaignManagement?.updateInternalMemoFail;

export const getFavoriteList = (state) => state.campaignManagement?.favoriteListData;
export const getFavoriteListPage = (state) => state.campaignManagement?.favoritePage;
export const getFavoriteListPageCount = (state) => state.campaignManagement?.favoritePageCount;
export const getFavoriteListTotalRow = (state) => state.campaignManagement?.favoriteTotalRow;

export const getInsightReportUuid = (state) => state.campaignManagement?.insightReportUuid;
export const getInsightReportList = (state) => state.campaignManagement?.insightReportListData;
export const getInsightReportListPage = (state) => state.campaignManagement?.insightReportPage;
export const getInsightReportListPageCount = (state) => state.campaignManagement?.insightReportPageCount;
export const getInsightReportListTotalRow = (state) => state.campaignManagement?.insightReportTotalRow;
export const getInsightReportSummary = (state) => state.campaignManagement?.insightReportSummaryData;
export const getInsightReportTop5Posts = (state) => state.campaignManagement?.insightReportTop5Posts;
export const getEngagementRateByTier = (state) => state.campaignManagement?.engagementRateByTier;
export const getEngagementRateByContentType = (state) => state.campaignManagement?.engagementRateByContentType;
export const getAccumulatedExposure = (state) => state.campaignManagement?.accumulatedExposure;
export const getInsightReportChannelId = (state) => state.campaignManagement?.insightReportChannelId;

export const getExportCampaignReport = (state) => state.campaignManagement?.exportReport;
export const getExportCampaignReportErrorDetailData = (state) => state.campaignManagement?.exportReportErrorDetailData;

export const getUpdateGuaranteeSuccess = (state) => state.campaignManagement?.updateGuaranteeSuccess;
export const getUpdateGuaranteeFail = (state) => state.campaignManagement?.updateGuaranteeFail;

export const getExportCampaignLiveReport = (state) => state.campaignManagement?.exportLiveReport;
export const getExportCampaignLiveReportErrorDetailData = (state) => state.campaignManagement?.exportLiveReportErrorDetailData;

export const getUpdateEmailTemplateSuccess = (state) => state.campaignManagement?.updateEmailTemplateSuccess;
export const getUpdateEmailTemplateFail = (state) => state.campaignManagement?.updateEmailTemplateFail;
export const getUpdateEmailTemplateResponseData = (state) => state.campaignManagement?.updateEmailTemplateResponseData;

export const getSendBlastEmailSuccess = (state) => state.campaignManagement?.sendBlastEmailSuccess;
export const getSendBlastEmailFail = (state) => state.campaignManagement?.sendBlastEmailFail;

export const getSendTestEmailSuccess = (state) => state.campaignManagement?.sendTestEmailSuccess;
export const getSendTestEmailFail = (state) => state.campaignManagement?.sendTestEmailFail;

export const getDuplicateCampaignSuccess = (state) => state.campaignManagement?.duplicateCampaignSuccess;
export const getDuplicateCampaignFail = (state) => state.campaignManagement?.duplicateCampaignFail;

export const getEmailTemplateJson = (state) => state.campaignManagement?.getEmailTemplateJson;

export const getCampaignTrackingProgressList = (state) => state.campaignManagement?.trackingListData;
export const getCampaignTrackingProgressListPage = (state) => state.campaignManagement?.trackingPage;
export const getCampaignTrackingProgressListPageCount = (state) => state.campaignManagement?.trackingPageCount;
export const getCampaignTrackingProgressListTotalRow = (state) => state.campaignManagement?.trackingTotalRow;

export const getCampaignReviewReportList = (state) => state.campaignManagement?.campaignReviewReportListData;
export const getCampaignReviewReportListPage = (state) => state.campaignManagement?.campaignReviewReportPage;
export const getCampaignReviewReportListPageCount = (state) => state.campaignManagement?.campaignReviewReportPageCount;
export const getCampaignReviewReportListTotalRow = (state) => state.campaignManagement?.campaignReviewReportTotalRow;
export const getCampaignReviewReportSummary = (state) => state.campaignManagement?.campaignReviewReportSummaryData;

export default CampaignManagementSlide.reducer;