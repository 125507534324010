import React, { Component, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import "./scss/style.scss";

// import i18n (needs to be bundled ;))
import "./i18n";

// routes config
import masterRoute from "src/routes/master_route";
import administratorRoute from "src/routes/administrator_route";
import managerRoute from "src/routes/manager_route";
import memberRoute from "src/routes/member_route";
import subMemberRoute from "src/routes/subMember_route";
import financeRoute from "src/routes/finance_route";

import { USER_ROLE } from "src/utils/constant_user_role";
import { getLoggedIn, getClientLoggedIn } from "src/reducers/reducer_core";

const loading = (
    <Box className="w-100 d-flex justify-content-center m-auto">
        <CircularProgress color="primary" />
    </Box>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Logout = React.lazy(() => import("./pages/logout/Logout"));
const Login = React.lazy(() => import("./pages/login/Login"));
const Register = React.lazy(() => import("./pages/register/Register"));
const Page404 = React.lazy(() => import("./pages/page404/Page404"));
const Page500 = React.lazy(() => import("./pages/page500/Page500"));
const ForgotPassword = React.lazy(() => import("./pages/forgot_password/ForgotPassword"));
const ForgotPasswordSent = React.lazy(() => import("./pages/forgot_password_sent/ForgotPasswordSent"));
const ResetPassword = React.lazy(() => import("./pages/reset_password/ResetPassword"));
const ResetPasswordSuccess = React.lazy(() => import("./pages/reset_password_success/ResetPasswordSuccess"));
const EmailChangedSuccess = React.lazy(() => import("./pages/email_changed_success/EmailChangedSuccess"));
const ViewExternalReport = React.lazy(() => import("./pages/campaign_management/view_external_report/index"));
const ViewLiveReport = React.lazy(() => import("./pages/campaign_management/view_live_report/index"));

// Client
const ClientLogout = React.lazy(() => import("./pages/client_logout/ClientLogout"));
const ClientDefaultLayout = React.lazy(() => import("./layout/ClientDefaultLayout"));
const ClientAuthenticate = React.lazy(() => import("./pages/client_authenticate/ClientAuthenticate"));
const ClientDraftReview = React.lazy(() => import("./pages/client_draft_review/ClientDraftReview"));
const ClientDraftReviewView = React.lazy(() => import("./pages/client_draft_review_view/ClientDraftReviewView"));

const App = () => {
    const loggedIn = useSelector(getLoggedIn);
    const clientLoggedIn = useSelector(getClientLoggedIn);

    return (
        <HashRouter>
            <Suspense fallback={loading}>
                <Routes>
                    <Route exact path="/logout" name="Logout Page" element={<Logout />} />
                    <Route exact path="/login" name="Login Page" element={<Login />} />
                    <Route exact path="/draft-review/:campaignId/authenticate" name="Client Authenticate Page" element={<ClientAuthenticate />} />
                    <Route exact path="/client-logout" name="Client Logout Page" element={<ClientLogout />} />
                    <Route exact path="/register" name="Register Page" element={<Register />} />
                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />
                    <Route exact path="/forgot-password" name="Forgot Password" element={<ForgotPassword />} />
                    <Route exact path="/forgot-password-sent" name="Forgot Password Sent" element={<ForgotPasswordSent />} />
                    <Route exact path="/reset-password/:token" name="Reset Password" element={<ResetPassword />} />
                    <Route exact path="/reset-password-success" name="Reset Password Success" element={<ResetPasswordSuccess />} />
                    <Route exact path="/email-changed-success" name="Email Changed Success" element={<EmailChangedSuccess />} />
                    <Route exact path="/view-external-report" name="Campaign Report" element={<ViewExternalReport />} />
                    <Route exact path="/view-live-report" name="Campaign Live Report" element={<ViewLiveReport />} />
                    
                    {clientLoggedIn?.loginType === "client" && (
                        <>
                            <Route path="client" name="Dashboard" element={<ClientDefaultLayout />} >
                                <Route exact path="/client/draft-review/:campaignId/submission" name="Client Draft Review" element={<ClientDraftReview />} />
                                <Route exact path="/client/draft-review/:campaignId/view/:campaignApplicationId" name="Client Draft Review View" element={<ClientDraftReviewView />} />
                            </Route>
                        </>
                    )}  

                    {loggedIn && (
                        <Route path="dashboard" name="Dashboard" element={<DefaultLayout />}>
                            {
                                loggedIn?.users?.slug === USER_ROLE.MASTER 
                                    ? masterRoute.map((route, idx) => {
                                        return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                    })
                                        : (loggedIn?.users?.slug === USER_ROLE.ADMINISTRATOR ) 
                                            ? administratorRoute.map((route, idx) => {
                                                return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                            })
                                        : (loggedIn?.users?.slug === USER_ROLE.MANAGER ) 
                                            ? managerRoute.map((route, idx) => {
                                                return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                            })
                                        : (loggedIn?.users?.slug === USER_ROLE.MEMBER ) 
                                            ? memberRoute.map((route, idx) => {
                                                return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                            })
                                        : (loggedIn?.users?.slug === USER_ROLE.FINANCE ) 
                                            ? financeRoute.map((route, idx) => {
                                                return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                            })
                                    : subMemberRoute.map((route, idx) => {
                                        return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                    })
                            }
                        </Route>
                    )}

                    {/* {clientLoggedIn?.loginType === "client" ? (
                        <>
                            <Route path="client" name="Dashboard" element={<ClientDefaultLayout />} >
                                <Route exact path="/client/draft-review/:campaignId/submission" name="Client Draft Review" element={<ClientDraftReview />} />
                                <Route exact path="/client/draft-review/:campaignId/view/:campaignApplicationId" name="Client Draft Review View" element={<ClientDraftReviewView />} />
                            </Route>
                        </>
                    ) : (
                        <Route path="dashboard" name="Dashboard" element={<DefaultLayout />}>
                            {
                                loggedIn?.users?.slug === USER_ROLE.MASTER 
                                    ? masterRoute.map((route, idx) => {
                                        return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                    })
                                        : (loggedIn?.users?.slug === USER_ROLE.ADMINISTRATOR ) 
                                            ? administratorRoute.map((route, idx) => {
                                                return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                            })
                                        : (loggedIn?.users?.slug === USER_ROLE.MANAGER ) 
                                            ? managerRoute.map((route, idx) => {
                                                return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                            })
                                        : (loggedIn?.users?.slug === USER_ROLE.MEMBER ) 
                                            ? memberRoute.map((route, idx) => {
                                                return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                            })
                                        : (loggedIn?.users?.slug === USER_ROLE.FINANCE ) 
                                            ? financeRoute.map((route, idx) => {
                                                return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                            })
                                    : subMemberRoute.map((route, idx) => {
                                        return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                                    })
                            }
                        </Route>
                    )} */}
                    
                    <Route path="/" name="Dashboard" element={<DefaultLayout />} />
                    <Route path="*" name="Not Found" element={<Page404 />} />
                </Routes>
            </Suspense>
        </HashRouter>
    );
};

export default App;
