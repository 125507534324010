import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const TaskSlide = createSlice({
    name: "task",
    initialState: {
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,
        taskDetail: null,
    },
    reducers: {
        doSetTaskDetailSuccess: (state, action) => {
            state.taskDetail = action.payload;
        },
        doGetTagManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetTagManagementDetailFail: (state, action) => {
            state.detailData = null;
        },
        doAddTaskSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
			state.responseData = action.payload;
        },
        doAddTaskFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateTaskSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doUpdateTaskFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doDeleteTaskSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doDeleteTaskFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        resetTaskStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            // state.taskDetail = null;
			state.errorDetailData = null;
        },
    },
});

export const doGetTagManagementDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/tag-management/${param.tag_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetTagManagementDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetTagManagementDetailFail(error.response));
    }
};

export const doAddTask = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign/task`;
        console.log(param);
        
        const response = await axios.post(url, param);
        dispatch(doAddTaskSuccess(response.data));
    } catch (error) {
        dispatch(doAddTaskFail(error.response));
    }
};

export const doUpdateTask = (param, taskId) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign/task/${taskId}`;

        const response = await axios.put(url, param);
        dispatch(doUpdateTaskSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateTaskFail(error.response));
    }
};

export const doDeleteTask = (taskId) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign/task/${taskId}`;

        const response = await axios.delete(url);
        dispatch(doDeleteTaskSuccess(response.data));
    } catch (error) {
        dispatch(doDeleteTaskFail(error.response));
    }
};

export const doSetTaskDetail = (param) => async (dispatch) => {
    dispatch(doSetTaskDetailSuccess(param));
};

export const resetTaskState = () => async (dispatch) => {
    dispatch(resetTaskStateSuccess(false));
 };

export const { 
    doGetTagManagementDetailSuccess,
    doGetTagManagementDetailFail,
    doAddTaskSuccess,
    doAddTaskFail,
    doUpdateTaskSuccess,
    doUpdateTaskFail,
    doDeleteTaskSuccess,
    doDeleteTaskFail,
    doSetTaskDetailSuccess,
    resetTaskStateSuccess,
} = TaskSlide.actions;

export const getTagManagementDetail = (state) => state.task?.detailData;
export const getTaskErrorDetailData = (state) => state.task?.errorDetailData;
export const getAddTaskSuccess = (state) => state.task?.success;
export const getAddTaskFail = (state) => state.task?.fail;
export const getAddTaskResponseData = (state) => state.task?.responseData;
export const getDeleteTaskSuccess = (state) => state.task?.success;
export const getDeleteTaskFail = (state) => state.task?.fail;
export const getTaskDetail = (state) => state.task?.taskDetail;

export default TaskSlide.reducer;
