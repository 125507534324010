import my_profile_route from "./modules/my_profile_route";
import dashboard_route from "./modules/dashboard_route";
import payout_route from "./modules/payout_route";

const routes = [
    ...my_profile_route,
    ...dashboard_route,
    ...payout_route,
];

export default routes;
