import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Avatar from '@mui/material/Avatar';
import { TbArrowNarrowUp, TbArrowNarrowDown } from "react-icons/tb";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DoneIcon from '@mui/icons-material/Done';
import avatar8 from '../../../../assets/images/avatars/8.jpg';
import { CASHOUT_STATUS } from "src/utils/constant_cashout_status";
import { CREDIT_TRANSACTION_STATUS } from "src/utils/enum";
import { addCommaAfterThousandWithTwoDecimalPlace } from "src/utils/functions";

const headCells = [
    {
        id: "influencerName",
        numeric: false,
        disablePadding: true,
        label: "ui.name",
    },
    {
        id: "campaign",
        numeric: true,
        disablePadding: false,
        label: "ui.campaign",
    },
    {
        id: "payoutAmount",
        numeric: true,
        disablePadding: false,
        label: "ui.payoutAmount",
    },
    {
        id: "status",
        numeric: true,
        disablePadding: false,
        label: "ui.status",
    },
    {
        id: "createdAt",
        numeric: true,
        disablePadding: false,
        label: "ui.createdAt",
    },
    {
        id: "action",
        numeric: true,
        disablePadding: false,
        label: "ui.action",
    },
];

function EnhancedTableHead(props) {
    const { t } = useTranslation(["core", "ui", "error_message"]);
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        {/* {headCell.id === "name" ?
                            <TableSortLabel
                                active
                                className="d-flex justify-content-between"
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={() => {
                                    return (
                                        <Box className="d-flex">
                                            <Box sx={{ marginRight: "-9px" }}>
                                                <TbArrowNarrowUp size="16px" color={orderBy === headCell.id ? (order === "desc" ? "#c9cacc" : "") : "#c9cacc"} />
                                            </Box>
                                            <Box>
                                                <TbArrowNarrowDown size="16px" color={orderBy === headCell.id ? (order === "asc" ? "#c9cacc" : "") : "#c9cacc"} />
                                            </Box>
                                        </Box>
                                    );
                                }}
                            >
                                {t(headCell.label, { ns: "ui"})}
                            </TableSortLabel>
                            : t(headCell.label, { ns: "ui"})
                        } */}
                        {t(headCell.label, { ns: "ui"})}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function TransactionTable({ data, onApprove }) {
    const { t } = useTranslation(["core", "ui", "error_message"]);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");

    const statusClass = (status) => {
        switch (status) {
            case CASHOUT_STATUS.PENDING:
                return "on-going";
            case CASHOUT_STATUS.APPROVED:
                return "approved";
            default:
                return "default";
        }
    };

    const statusName = (status) => {
        switch (status) {
            case CASHOUT_STATUS.PENDING:
                return t("ui.Pending", { ns: "ui"});
            case CASHOUT_STATUS.APPROVED:
                return t("ui.Approved", { ns: "ui"});
            default:
                return "-";
        }
    };
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Paper elevation={0} sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={data?.length} />

                        <TableBody>
                            {data?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className="table-content" style={{ padding: "0.75rem 0.75rem"}}>
                                        <div className="d-flex align-items-center">
                                            <Avatar src={item?.profileFileManaged?.filepath} size="md" sx={{ marginRight: "0.5rem"}} />
                                            <div>{item?.influencerName}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className="table-content">{item?.campaignTitle}</TableCell>
                                    <TableCell className="table-content">{addCommaAfterThousandWithTwoDecimalPlace(item?.credit)}</TableCell>
                                    <TableCell className="table-content">
                                        <Box px={1} sx={{ width: "fit-content" }} className={`text-center status-tag-${statusClass(item?.status)}`}>
                                            {statusName(item.status)}
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-content">{moment(item?.createdAt).format("YYYY-MM-DD")}</TableCell>
                                    <TableCell className="table-content">
                                        {item.status === CREDIT_TRANSACTION_STATUS.PENDING && (
                                            <IconButton
                                                size="small"
                                                className="icon-button-green"
                                                sx={{
                                                    marginLeft: "8px",

                                                }}
                                                onClick={() => {
                                                    onApprove(item);
                                                }}
                                            >
                                                <DoneIcon fontSize="inherit" />
                                            </IconButton>
                                        )}
                                    </TableCell>    
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}
