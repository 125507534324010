import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const dashboardSlide = createSlice({
    name: "dashboard",
    initialState: {
        activityLog: null,
        errorDetailData: null,
    },
    reducers: {
        doGetActivityLogSuccess: (state, action) => {
            state.activityLog = action.payload;
        },
        doGetActivityLogFail: (state, action) => {
            state.activityLog = null;
            state.errorDetailData = action.payload.data;
        },
        resetDashobardState: (state, action) => {
            state.errorDetailData = null;
        },
    },
});

export const doGetActivityLog = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/dashboard/activity-log`;

        const response = await axios.post(url, param);
        dispatch(doGetActivityLogSuccess(response.data));
    } catch (error) {
        dispatch(doGetActivityLogFail(error.response));
    }
};

export const doResetDashobardState = () => async (dispatch) => {
    dispatch(resetDashobardState(null));
};

export const { 
    doGetActivityLogSuccess,
    doGetActivityLogFail,
    resetDashobardState,
} = dashboardSlide.actions;

export const getActivityLog = (state) => state.dashboard?.activityLog;
export const getErrorDetailData = (state) => state.dashboard?.errorDetailData;

export default dashboardSlide.reducer;
