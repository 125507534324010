import React from "react";
import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export const ErrorMessageDialog = ({ handleClose, handleSubmit, isOpen, buttonLabel, title, children }) => {
	return (
		<Dialog onClose={handleClose} open={isOpen} maxWidth="xs" style={{ minWidth: "30%" }} fullWidth>
			<DialogTitle id="confirmation-dialog-title" className="text-center" style={{ color: "#4668E5" }}>
				<Typography color="primary" variant="h5">
					{title}
				</Typography>
			</DialogTitle>
			<Divider style={{ width: "93%" }} className="m-auto" />
			<DialogContent>{children}</DialogContent>
			<DialogActions className="justify-content-center mb-2">
				<Button  disableRipple 
					onClick={handleSubmit || handleClose}
					autoFocus
					className="w-50"
					variant="contained"
					size="medium"
				>
					{buttonLabel}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessageDialog);
