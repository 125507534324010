import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import core from "src/reducers/reducer_core";
import userManagement from "src/reducers/reducer_user_management";
import myProfile from "src/reducers/reducer_my_profile";
import brand from "src/reducers/reducer_brand";
import campaignGroup from "src/reducers/reducer_campaign_group";
import tagManagement from "src/reducers/reducer_tag_management";
import influencer from "src/reducers/reducer_influencer";
import privilege from "src/reducers/reducer_privilege";
import campaignApplication from "src/reducers/reducer_campaign_application";
import payout from "src/reducers/reducer_payout";
import campaignManagement from "src/reducers/reducer_campaign_management";
import task from "src/reducers/reducer_task";
import subtask from "src/reducers/reducer_subtask";
import campaignSubmission from "src/reducers/reducer_campaign_submission";
import forgotPassword from "src/reducers/reducer_forgot_password";
import dashboard from "src/reducers/reducer_dashboard";

const appReducer = combineReducers({
    core,
    userManagement,
    myProfile,
    brand,
    campaignGroup,
    tagManagement,
    influencer,
    privilege,
    campaignApplication,
    payout,
    campaignManagement,
    task,
    subtask,
    campaignSubmission,
    forgotPassword,
    dashboard,
});

const rootReducer = (state, action) => {
    if (action.type === "USER_LOGOUT") {
        Object.keys(state).forEach((key) => {
            storage.removeItem(`persist:${key}`);
        });
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
