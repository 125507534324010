import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const TagManagementSlide = createSlice({
    name: "tagManagement",
    initialState: {
        page: 0,
        pageCount: 0,
        totalRow: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,
    },
    reducers: {
        doSetTagManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload;
        },
        doGetTagManagementListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
            state.totalRow = action.payload?.total_row;
        },
        doGetTagManagementListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetTagManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetTagManagementDetailFail: (state, action) => {
            state.detailData = null;
        },
        doAddTagManagementSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
			state.responseData = action.payload;
        },
        doAddTagManagementFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateTagManagementSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doUpdateTagManagementFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doDeleteTagManagementSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doDeleteTagManagementFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        resetTagManagementStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
			state.errorDetailData = null;
        },
    },
});

export const doGetTagManagementList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/tag-management?page=${param.page}&size=${param.limit}`;
       
        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetTagManagementListSuccess(response.data));
    } catch (error) {
        dispatch(doGetTagManagementListFail(error.response));
    }
};

export const doGetTagManagementDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/tag-management/${param.tag_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetTagManagementDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetTagManagementDetailFail(error.response));
    }
};

export const doAddTagManagement = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/tag-management`;

        const response = await axios.post(url, param);
        dispatch(doAddTagManagementSuccess(response.data));
    } catch (error) {
        dispatch(doAddTagManagementFail(error.response));
    }
};

export const doUpdateTagManagement = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/tag-management/${param.tag_id}`;

        const response = await axios.put(url, param);
        dispatch(doUpdateTagManagementSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateTagManagementFail(error.response));
    }
};

export const doDeleteTagManagement = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/tag-management/${param.tag_id}`;

        const response = await axios.delete(url);
        dispatch(doDeleteTagManagementSuccess(response.data));
    } catch (error) {
        dispatch(doDeleteTagManagementFail(error.response));
    }
};

export const doSetTagManagementDetail = (param) => async (dispatch) => {
    dispatch(doSetTagManagementDetailSuccess(param));
};

export const resetTagManagementState = () => async (dispatch) => {
    dispatch(resetTagManagementStateSuccess(false));
 };

export const { 
    doGetTagManagementListSuccess, 
    doGetTagManagementListFail, 
    doGetTagManagementDetailSuccess,
    doGetTagManagementDetailFail,
    doAddTagManagementSuccess,
    doAddTagManagementFail,
    doUpdateTagManagementSuccess,
    doUpdateTagManagementFail,
    doDeleteTagManagementSuccess,
    doDeleteTagManagementFail,
    doSetTagManagementDetailSuccess,
    resetTagManagementStateSuccess,
} = TagManagementSlide.actions;

export const getTagManagementList = (state) => state.tagManagement?.listData;
export const getTagManagementListPage = (state) => state.tagManagement?.page;
export const getTagManagementListPageCount = (state) => state.tagManagement?.pageCount;
export const getTagManagementListTotalRow = (state) => state.tagManagement?.totalRow;
export const getTagManagementDetail = (state) => state.tagManagement?.detailData;
export const getTagManagementErrorDetailData = (state) => state.tagManagement?.errorDetailData;
export const getAddTagManagementSuccess = (state) => state.tagManagement?.success;
export const getAddTagManagementFail = (state) => state.tagManagement?.fail;
export const getAddTagManagementResponseData = (state) => state.tagManagement?.responseData;
export const getDeleteTagManagementSuccess = (state) => state.tagManagement?.success;
export const getDeleteTagManagementFail = (state) => state.tagManagement?.fail;

export default TagManagementSlide.reducer;
