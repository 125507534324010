import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form';
import { CCard, CCardBody } from "@coreui/react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Fab } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import InputTableSelectControl from "src/components/inputs/InputTableSelectControl";
import TransactionTable from "./components/tables/TransactionTable";
import ConfirmationDialog from "src/components/dialogs/ConfirmationDialog";
import ErrorMessageDialog from "src/components/dialogs/ErrorMessageDialog";
import {
    doGetTransactionList,
    getTransactionList,
    getTransactionListPage,
    getTransactionListPageCount,
    getTransactionListTotalRow,
    doApproveTransaction,
    getApproveTransactionSuccess,
    getApproveTransactionFail,
    getApproveTransactionErrorDetailData,
    resetPayoutState,
} from "src/reducers/reducer_payout";
import {
    doGetCampaignFilterData,
    getCampaignFilterData,
} from "src/reducers/reducer_campaign_management";

const TransactionList = () => {
    const { t } = useTranslation(["core", "ui", "error_message"]);
    const list = useSelector(getTransactionList);
    const currentPage = useSelector(getTransactionListPage);
    const pageCount = useSelector(getTransactionListPageCount);
    const totalRow = useSelector(getTransactionListTotalRow);
    const approveTransactionSuccess = useSelector(getApproveTransactionSuccess);
    const approveTransactionFail = useSelector(getApproveTransactionFail);
    const approveTransactionErrorDetailData = useSelector(getApproveTransactionErrorDetailData);
    const dispatch = useDispatch();
    const { control, getValues, setValue } = useForm({
        defaultValues: {
            filterCampaign: "",
        },
        shouldFocusError: false
    });
    const [entries, setEntries] = useState(10);
    const PreviousIcon = () => <Typography>{t("ui.previous", { ns: "ui" })}</Typography>;
    const NextIcon = () => <Typography>{t("ui.next", { ns: "ui" })}</Typography>;
    const [filters, setFilters] = useState(null);
    const [approveCreditTransactionId, setApproveCreditTransactionId] = useState(null);
    const [isOpenConfirmApproveDialog, setIsOpenConfirmApproveDialog] = useState(false);
    const [isOpenErrorMessageDialog, setIsOpenErrorMessageDialog] = useState(false);
    const filterData = useSelector(getCampaignFilterData);

    useEffect(() => {
        dispatch(resetPayoutState());
        dispatch(doGetCampaignFilterData());
        dispatch(doGetTransactionList({ page: 1, limit: entries }));
    }, []);

    const onApprove = (item) => {
        setApproveCreditTransactionId(item.id);
        setIsOpenConfirmApproveDialog(true);
    }

    useEffect(() => {
        if (approveTransactionSuccess) {
            setIsOpenErrorMessageDialog(false);
            dispatch(resetPayoutState());
            dispatch(doGetTransactionList({ page: currentPage, limit: entries, filter: filters }));
        }

        if (approveTransactionFail) {
            setIsOpenErrorMessageDialog(true);
        }
    }, [approveTransactionSuccess, approveTransactionFail]);

    const handleFilter = () => {
        const data = getValues();

        setFilters({
            ...filters,
            ...data,
        });

        dispatch(
            doGetTransactionList({
                page: 1,
                limit: entries,
                filter: {
                    ...filters,
                    ...data,
                }
            })
        );
    }

    return (
        <>
            <ErrorMessageDialog
                title={t("ui.error", { ns: "ui" }) + "!"}
                buttonLabel="OK"
                isOpen={isOpenErrorMessageDialog}
                handleClose={() => {
                    dispatch(resetPayoutState());
                    setIsOpenErrorMessageDialog(!isOpenErrorMessageDialog);
                }}
            >
                {approveTransactionErrorDetailData?.data?.errorMessage}
            </ErrorMessageDialog>

            <ConfirmationDialog
                title="Approve Transaction"
                buttonLabel="OK"
                isOpen={isOpenConfirmApproveDialog}
                showCancel="true"
                handleClose={() => setIsOpenConfirmApproveDialog(!isOpenConfirmApproveDialog)}
                handleSubmit={() => {
                    setIsOpenConfirmApproveDialog(!isOpenConfirmApproveDialog);
                    console.log(approveCreditTransactionId);
                    if (approveCreditTransactionId != null) {
                        dispatch(doApproveTransaction({
                            creditTransactionId: approveCreditTransactionId,
                        }))
                        setApproveCreditTransactionId(null);
                    }
                }}
            >
                <div>
                    <Typography variant="body2" >Are you want to approve ?</Typography>
                </div>
            </ConfirmationDialog>

            <CCard style={{ height: "inherit" }}>
                <CCardBody className="d-flex flex-column justify-content-between">

                    <div className="row mb-4">

                        <div className="col-md-4">
                            <InputTableSelectControl
                                name="filterCampaign"
                                control={control}
                                label={t("ui.campaign", { ns: "ui" })}
                                options={filterData?.campaign_list}
                                type={"campaignList"}
                            />
                        </div>

                        <div className="col-md-1">
                            <Fab
                                color="primary"
                                aria-label="add"
                                size="small"
                                onClick={handleFilter}
                                sx={{
                                    marginTop: "18px"
                                }}
                            >
                                <SearchIcon fontSize={'small'} />
                            </Fab>
                        </div>
                    </div>

                    <TransactionTable
                        data={list}
                        onApprove={onApprove}
                    />

                    <Box sx={{ width: "100%" }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} sm={6}>
                                <div className="mb-3 d-flex" style={{ fontSize: "14px" }}>
                                    {t("ui.showing", { ns: "ui" })} {currentPage} {t("ui.to", { ns: "ui" })} {pageCount} {t("ui.of", { ns: "ui" })} {totalRow} {t("ui.entries", { ns: "ui" })}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <div className="d-flex justify-content-end">
                                    <Pagination
                                        shape="rounded"
                                        count={pageCount}
                                        page={currentPage}
                                        onChange={(event, page) => {
                                            dispatch(
                                                doGetTransactionList({ page, limit: entries })
                                            )
                                        }}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                components={{ previous: PreviousIcon, next: NextIcon }}
                                                {...item}
                                            />
                                        )}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Box>

                </CCardBody>
            </CCard>
        </>
    )
}

export default TransactionList