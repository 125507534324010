import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const brandSlide = createSlice({
    name: "brand",
    initialState: {
        page: 0,
        pageCount: 0,
        totalRow: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,
        filterData: null,
        campaignListData: null,
        campaignPage: 0,
        campaignPageCount: 0,
        campaignTotalRow: 0,
    },
    reducers: {
        doSetBrandDetailSuccess: (state, action) => {
            state.detailData = action.payload;
        },
        doGetBrandListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
            state.totalRow = action.payload?.total_row;
        },
        doGetBrandListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetBrandDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetBrandDetailFail: (state, action) => {
            state.detailData = null;
        },
        doAddBrandSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
			state.responseData = action.payload;
        },
        doAddBrandFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doUpdateBrandSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doUpdateBrandFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doDeleteBrandSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doDeleteBrandFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doGetBrandCampaignListSuccess: (state, action) => {
            state.campaignListData = action.payload.data;
            state.campaignPage = action.payload?.current_page;
			state.campaignPageCount = action.payload?.page_count;
            state.campaignTotalRow = action.payload?.total_row;
        },
        doGetBrandCampaignListFail: (state, action) => {
            state.campaignListData = null;
            state.campaignPage = 0;
			state.campaignPageCount = 0;
            state.campaignTotalRow = 0;
        },
        setBrandFilterData: (state, action) => {
            state.filterData = action.payload;
        },
        resetBrandStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
			state.errorDetailData = null;
        },
    },
});

export const doGetBrandList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/brand?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetBrandListSuccess(response.data));
    } catch (error) {
        dispatch(doGetBrandListFail(error.response));
    }
};

export const doGetBrandDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/brand/${param.brandId}`;
       
        const response = await axios.get(url);
        dispatch(doGetBrandDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetBrandDetailFail(error.response));
    }
};

export const doAddBrand = (param) => async (dispatch) => {
    try {

        let formData = new FormData();

        for (const field in param) {
            const value = param[field];

            if (field !== 'attachment') {
                formData.append(
                    field, value
                );
            }
        }

        if (param.attachment !== null && param.attachment !== "") {
            formData.append('attachment', param.attachment);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const url = `/api/admin/brand`;

        const response = await axios.post(url, formData, config);
        dispatch(doAddBrandSuccess(response.data));
    } catch (error) {
        dispatch(doAddBrandFail(error.response));
    }
};

export const doUpdateBrand = (param, brandId) => async (dispatch) => {
    try {

        let formData = new FormData();

        for (const field in param) {
            const value = param[field];

            if (field !== 'attachment') {
                formData.append(
                    field, value
                );
            }
        }

        if (param.attachment !== null && param.attachment !== "") {
            formData.append('attachment', param.attachment);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const url = `/api/admin/brand/${brandId}`;

        const response = await axios.put(url, formData, config);
        dispatch(doUpdateBrandSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateBrandFail(error.response));
    }
};

export const doDeleteBrand = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/brand/${param.brandId}`;

        const response = await axios.delete(url, param);
        dispatch(doDeleteBrandSuccess(response.data));
    } catch (error) {
        dispatch(doDeleteBrandFail(error.response));
    }
};

export const doGetBrandFilterData = () => async (dispatch) => {
    try {
        let url = `/api/admin/brand/filter/data`;
       
        const response = await axios.get(url);
        dispatch(setBrandFilterData(response.data));
    } catch (error) {
        dispatch(setBrandFilterData(error.response));
    }
};

export const doGetBrandCampaignList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/brand-campaign/${param.brandId}?page=${param.page}&size=${param.limit}`;

        const response = await axios.get(url);
        dispatch(doGetBrandCampaignListSuccess(response.data));
    } catch (error) {
        dispatch(doGetBrandCampaignListFail(error.response));
    }
};

export const doSetBrandDetail = (param) => async (dispatch) => {
    dispatch(doSetBrandDetailSuccess(param));
};

export const resetBrandState = () => async (dispatch) => {
    dispatch(resetBrandStateSuccess(false));
};

export const { 
    doGetBrandListSuccess, 
    doGetBrandListFail, 
    doGetBrandDetailSuccess,
    doGetBrandDetailFail,
    doAddBrandSuccess,
    doAddBrandFail,
    doUpdateBrandSuccess,
    doUpdateBrandFail,
    doDeleteBrandSuccess,
    doDeleteBrandFail,
    doGetBrandCampaignListSuccess,
    doGetBrandCampaignListFail,
    doSetBrandDetailSuccess,
    setBrandFilterData,
    resetBrandStateSuccess,
} = brandSlide.actions;

export const getBrandList = (state) => state.brand?.listData;
export const getBrandListPage = (state) => state.brand?.page;
export const getBrandListPageCount = (state) => state.brand?.pageCount;
export const getBrandListTotalRow = (state) => state.brand?.totalRow;
export const getBrandDetail = (state) => state.brand?.detailData;
export const getBrandErrorDetailData = (state) => state.brand?.errorDetailData;
export const getAddBrandSuccess = (state) => state.brand?.success;
export const getAddBrandFail = (state) => state.brand?.fail;
export const getAddBrandResponseData = (state) => state.brand?.responseData;
export const getDeleteBrandSuccess = (state) => state.brand?.success;
export const getDeleteBrandFail = (state) => state.brand?.fail;
export const getBrandFilterData = (state) => state.brand?.filterData;

export const getBrandCampaignList = (state) => state.brand?.campaignListData;
export const getBrandCampaignListPage = (state) => state.brand?.campaignPage;
export const getBrandCampaignListPageCount = (state) => state.brand?.campaignPageCount;
export const getBrandCampaignListTotalRow = (state) => state.brand?.campaignTotalRow;

export default brandSlide.reducer;
