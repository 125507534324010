import my_profile_route from "./modules/my_profile_route";
import brand_route from "./modules/brand_route";
import campaign_group_route from "./modules/campaign_group_route";
import tag_management_route from "./modules/tag_management_route";
import campaign_management_route from "./modules/campaign_management_route";
import influencer_route from "./modules/influencer_route";
import payout_route from "./modules/payout_route";
import campaign_submission_route from "./modules/campaign_submission_route";
import privilege_route from "./modules/privilege_route";
import user_route from "./modules/user_route";
import dashboard_route from "./modules/dashboard_route";

const routes = [
    ...my_profile_route,
    ...brand_route,
    ...campaign_group_route,
    ...tag_management_route,
    ...campaign_management_route,
    ...influencer_route,
    ...payout_route,
    ...campaign_submission_route,
    ...privilege_route,
    ...user_route,
    ...dashboard_route,
];

export default routes;
