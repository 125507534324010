import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const PrivilegeSlide = createSlice({
    name: "privilege",
    initialState: {
        page: 0,
        pageCount: 0,
        totalRow: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,
    },
    reducers: {
        doGetPrivilegeListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
			state.pageCount = action.payload?.page_count;
            state.totalRow = action.payload?.total_row;
        },
        doGetPrivilegeListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetPrivilegeDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetPrivilegeDetailFail: (state, action) => {
            state.detailData = null;
        },
        doUpdateRolePrivilegeSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doUpdateRolePrivilegeFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        doDeactivePrivilegeSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doDeactivePrivilegeFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        resetPrivilegeStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
			state.errorDetailData = null;
        },
    },
});

export const doGetPrivilegeList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/privilege?page=${param.page}&size=${param.limit}`;
        if(param.name) {
            url += `&name=${encodeURIComponent(param.name)}`;
        }

        const response = await axios.get(url);
        dispatch(doGetPrivilegeListSuccess(response.data));
    } catch (error) {
        dispatch(doGetPrivilegeListFail(error.response));
    }
};

export const doGetPrivilegeDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/privilege/${param.user_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetPrivilegeDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetPrivilegeDetailFail(error.response));
    }
};

export const doUpdateRolePrivilege = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/privilege/${param.user_id}/update-role`;

        const response = await axios.put(url, param);
        dispatch(doUpdateRolePrivilegeSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateRolePrivilegeFail(error.response));
    }
};

export const doDeactivePrivilege = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/privilege/${param.user_id}/deactive`;

        const response = await axios.delete(url);
        dispatch(doDeactivePrivilegeSuccess(response.data));
    } catch (error) {
        dispatch(doDeactivePrivilegeFail(error.response));
    }
};

export const resetPrivilegeState = () => async (dispatch) => {
    dispatch(resetPrivilegeStateSuccess(false));
 };

export const { 
    doGetPrivilegeListSuccess, 
    doGetPrivilegeListFail, 
    doGetPrivilegeDetailSuccess,
    doGetPrivilegeDetailFail,
    doUpdateRolePrivilegeSuccess,
    doUpdateRolePrivilegeFail,
    doDeactivePrivilegeSuccess,
    doDeactivePrivilegeFail,
    resetPrivilegeStateSuccess,
} = PrivilegeSlide.actions;

export const getPrivilegeList = (state) => state.privilege?.listData;
export const getPrivilegeListPage = (state) => state.privilege?.page;
export const getPrivilegeListPageCount = (state) => state.privilege?.pageCount;
export const getPrivilegeListTotalRow = (state) => state.privilege?.totalRow;
export const getPrivilegeDetail = (state) => state.privilege?.detailData;
export const getPrivilegeErrorDetailData = (state) => state.privilege?.errorDetailData;
export const getUpdateRolePrivilegeSuccess = (state) => state.privilege?.success;
export const getUpdateRolePrivilegeFail = (state) => state.privilege?.fail;
export const getUpdateRolePrivilegeResponseData = (state) => state.privilege?.responseData;
export const getDeactivePrivilegeSuccess = (state) => state.privilege?.success;
export const getDeactivePrivilegeFail = (state) => state.privilege?.fail;

export default PrivilegeSlide.reducer;
