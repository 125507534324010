import React from "react";

const TagManagementList = React.lazy(() => import('src/pages/tag_management/TagManagementList'));
const ViewTagManagement = React.lazy(() => import('src/pages/tag_management/ViewTagManagement'));
const AddTagManagement = React.lazy(() => import('src/pages/tag_management/AddTagManagement'));
const EditTagManagement = React.lazy(() => import('src/pages/tag_management/EditTagManagement'));

const routes = [
    { path: '/dashboard/tag-management/add', name: 'Add Tag', element: AddTagManagement },
    { path: '/dashboard/tag-management/edit', name: 'Edit Tag', element: EditTagManagement },
    { path: '/dashboard/tag-management/:param', withParam: true, exact: true, name: "", element: ViewTagManagement },
    { path: '/dashboard/tag-management', name: 'Tags', element: TagManagementList },
];

export default routes;