import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const CampaignSubmissionSlide = createSlice({
    name: "campaignSubmission",
    initialState: {
        page: 0,
        pageCount: 0,
        totalRow: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,
        selectedReportInsight: {
            form_fields: null,
            form_data: null,
        },
        selectedTaskSubmissionSubtaskId: null,
    },
    reducers: {
        doGetCampaignSubmissionListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload?.current_page;
            state.pageCount = action.payload?.page_count;
            state.totalRow = action.payload?.total_row;
        },
        doGetCampaignSubmissionListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
            state.pageCount = 0;
        },
        doGetCampaignSubmissionDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetCampaignSubmissionDetailFail: (state, action) => {
            state.detailData = null;
        },
        doUpdateStatusCampaignSubmissionSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
            state.responseData = action.payload;
        },
        doUpdateStatusCampaignSubmissionFail: (state, action) => {
            state.success = false;
            state.fail = true;
            state.errorDetailData = action.payload;
        },
        doUpdateReportInsightCampaignSubmissionSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
            state.responseData = action.payload;
        },
        doUpdateReportInsightCampaignSubmissionFail: (state, action) => {
            state.success = false;
            state.fail = true;
            state.errorDetailData = action.payload;
        },
        doUpdateDraftSubmissionSubmittedFeedbackCampaignSubmissionSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
            state.responseData = action.payload;
        },
        doUpdateDraftSubmissionSubmittedFeedbackCampaignSubmissionFail: (state, action) => {
            state.success = false;
            state.fail = true;
            state.errorDetailData = action.payload;
        },
        resetCampaignSubmissionStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
            state.errorDetailData = null;
        },
        updateIsOpenReportInsightDrawer: (state, action) => {
            state.isOpenReportInsightDrawer = action.payload;
        },
        updateSelectedReportInsightSuccess: (state, action) => {
            state.selectedReportInsight = action.payload;
        },
        updateSelectedTaskSubmissionSubtaskIdSuccess: (state, action) => {
            state.selectedTaskSubmissionSubtaskId = action.payload;
        },
    },
});

export const doGetCampaignSubmissionList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign-submission?page=${param.page}&size=${param.limit}`;
        if (param.campaign_id) {
            url += `&campaign_id=${param.campaign_id}`;
        }
        if (param.influencer) {
            url += `&influencer=${param.influencer.value}`;
        }
        if (param.status) {
            url += `&status=${param.status}`;
        }
        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetCampaignSubmissionListSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignSubmissionListFail(error.response));
    }
};

export const doGetCampaignSubmissionDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign-submission/view?campaign_application_id=${param.campaign_application_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetCampaignSubmissionDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignSubmissionDetailFail(error.response));
    }
};

export const doUpdateStatusCampaignSubmission = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign-submission/update-status`;

        const response = await axios.put(url, param);
        dispatch(doUpdateStatusCampaignSubmissionSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateStatusCampaignSubmissionFail(error.response));
    }
};

export const doUpdateReportInsightCampaignSubmission = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign-submission/update-report-insight`;

        const response = await axios.put(url, param);
        dispatch(doUpdateReportInsightCampaignSubmissionSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateReportInsightCampaignSubmissionFail(error.response));
    }
};

export const doUpdateDraftSubmissionSubmittedFeedbackCampaignSubmission = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign-submission/update-draft-submission-feedback`;

        const response = await axios.put(url, param);
        dispatch(doUpdateDraftSubmissionSubmittedFeedbackCampaignSubmissionSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateDraftSubmissionSubmittedFeedbackCampaignSubmissionFail(error.response));
    }
};

export const resetCampaignSubmissionState = () => async (dispatch) => {
    dispatch(resetCampaignSubmissionStateSuccess(false));
};

export const updateCampaignSubmissionDrawerFlag = (param) => async (dispatch) => {
    dispatch(updateIsOpenReportInsightDrawer(param));
};

export const updateSelectedReportInsight = (param) => async (dispatch) => {
    dispatch(updateSelectedReportInsightSuccess(param));
};

export const updateSelectedTaskSubmissionSubtaskId = (param) => async (dispatch) => {
    dispatch(updateSelectedTaskSubmissionSubtaskIdSuccess(param));
};

export const {
    doGetCampaignSubmissionListSuccess,
    doGetCampaignSubmissionListFail,
    doGetCampaignSubmissionDetailSuccess,
    doGetCampaignSubmissionDetailFail,
    doUpdateStatusCampaignSubmissionSuccess,
    doUpdateStatusCampaignSubmissionFail,
    doUpdateReportInsightCampaignSubmissionSuccess,
    doUpdateReportInsightCampaignSubmissionFail,
    doUpdateDraftSubmissionSubmittedFeedbackCampaignSubmissionSuccess,
    doUpdateDraftSubmissionSubmittedFeedbackCampaignSubmissionFail,
    resetCampaignSubmissionStateSuccess,
    updateIsOpenReportInsightDrawer,
    updateSelectedReportInsightSuccess,
    updateSelectedTaskSubmissionSubtaskIdSuccess,
} = CampaignSubmissionSlide.actions;

export const getCampaignSubmissionList = (state) => state.campaignSubmission?.listData;
export const getCampaignSubmissionListPage = (state) => state.campaignSubmission?.page;
export const getCampaignSubmissionListPageCount = (state) => state.campaignSubmission?.pageCount;
export const getCampaignSubmissionListTotalRow = (state) => state.campaignSubmission?.totalRow;
export const getCampaignSubmissionDetail = (state) => state.campaignSubmission?.detailData;
export const getCampaignSubmissionErrorDetailData = (state) => state.campaignSubmission?.errorDetailData;
export const getUpdateStatusCampaignSubmissionSuccess = (state) => state.campaignSubmission?.success;
export const getUpdateStatusCampaignSubmissionFail = (state) => state.campaignSubmission?.fail;
export const getUpdateDraftSubmissionSubmittedFeedbackCampaignSubmissionErrorDetailData = (state) => state.campaignSubmission?.errorDetailData;
export const getUpdateDraftSubmissionSubmittedFeedbackCampaignSubmissionSuccess = (state) => state.campaignSubmission?.success;
export const getUpdateDraftSubmissionSubmittedFeedbackCampaignSubmissionFail = (state) => state.campaignSubmission?.fail;
export const getUpdateStatusCampaignSubmissionResponseData = (state) => state.campaignSubmission?.responseData;
export const getCampaignReportInsightDrawerFlag = (state) => state.campaignSubmission?.isOpenReportInsightDrawer;
export const getSelectedReportInsight = (state) => state.campaignSubmission?.selectedReportInsight;
export const getSelectedTaskSubmissionSubtaskId = (state) => state.campaignSubmission?.selectedTaskSubmissionSubtaskId;


export default CampaignSubmissionSlide.reducer;
